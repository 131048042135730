/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

import commonApi from '../../../../api/common-api'
import contractsApi from '../../../../apps/XWork/api/contracts-api'
import responsesApi from '../../../../apps/XWork/api/responses-api'
import vacanciesApi from '../../../../apps/XWork/api/vacancies-api'

import { addNot } from '../../common/global-nots/global-nots'

const initialState = {
	vacancies: [],
	contracts: [],
	count: 0,
	vacancyInfo: {},
	contractInfo: {},
	filters: {
		limit: 10,
		offset: 0,
		is_beginner: true,
		work_schedule: [],
		is_suitable: false,
		min_payment: 0,
		max_payment: 0,
		currency_key: 'rub',
		skills: [],
	},
	minPossiblePayment: 0,
	maxPossiblePayment: 0,
	isLoading: false,
	isLoaded: false,
	failed: false,
}

const beginners = createSlice({
	name: 'beginners',
	initialState,
	reducers: {
		setVacancies: (state, action) => {
			state.vacancies = action.payload.vacancies
		},

		setVacancyInfo: (state, action) => {
			state.vacancyInfo = action.payload.info
		},

		setContracts: (state, action) => {
			state.contracts = action.payload.contracts
		},

		setContractInfo: (state, action) => {
			state.contractInfo = action.payload.info
		},

		setCount: (state, action) => {
			state.count = action.payload.count
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setMinPossiblePayment: (state, action) => {
			state.minPossiblePayment = action.payload.minPossiblePayment
		},

		setMaxPossiblePayment: (state, action) => {
			state.maxPossiblePayment = action.payload.maxPossiblePayment
		},

		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},

		setIsLoaded: (state, action) => {
			state.isLoaded = action.payload.isLoaded
		},

		setFailed: (state, action) => {
			state.failed = action.payload.failed
		},
	},
})

const { actions, reducer } = beginners

export const {
	setVacancies,
	setVacancyInfo,
	setContracts,
	setContractInfo,
	setCount,
	setFilters,
	setMinPossiblePayment,
	setMaxPossiblePayment,
	setIsLoading,
	setIsLoaded,
	setFailed,
} = actions

// Получение списка вакансий
export const requestVacancies = (clean) => async (dispatch, getState) => {
	const {
		beginners: { vacancies, filters, isLoaded },
	} = getState()

	dispatch(setIsLoading({ isLoading: true }))

	const res = await vacanciesApi.requestVacancies(filters)

	if (res.success) {
		if (clean) dispatch(setVacancies({ vacancies: res.result.vacancy }))
		else dispatch(setVacancies({ vacancies: [...vacancies, ...res.result.vacancy] }))

		dispatch(setCount({ count: res.result.count }))

		if (!isLoaded) {
			dispatch(setIsLoaded({ isLoaded: true }))
		}
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// Получение информации о вакансии по id
export const requestVacancyById = (id) => async (dispatch) => {
	const res = await vacanciesApi.requestVacancyById(id)

	if (res.success) {
		dispatch(setVacancyInfo({ info: res.result }))
	} else {
		dispatch(setFailed({ failed: true }))
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Получение списка контрактов
export const requestContracts = (clean) => async (dispatch, getState) => {
	const {
		beginners: { contracts, filters, isLoaded },
	} = getState()

	dispatch(setIsLoading({ isLoading: true }))

	const res = await contractsApi.requestContracts(filters)

	if (res.success) {
		if (clean) dispatch(setContracts({ contracts: res.result.contracts }))
		else dispatch(setContracts({ contracts: [...contracts, ...res.result.contracts] }))

		dispatch(setCount({ count: res.result.count }))

		if (!isLoaded) {
			dispatch(setIsLoaded({ isLoaded: true }))
		}
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// Получение информации о вакансии по id
export const requestContractById = (id) => async (dispatch) => {
	const res = await contractsApi.requestContractById(id)

	if (res.success) {
		dispatch(setContractInfo({ info: res.result }))
	} else {
		dispatch(setFailed({ failed: true }))
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Получение минимальной и максимальной зарплаты для фильтра
export const requestMinMax = (type) => async (dispatch) => {
	// Запрос за зп вакансий - поменять на вакансии для начинающих
	const res = await commonApi.requestMinMax(11, type)

	if (res.success) {
		dispatch(setMinPossiblePayment({ minPossiblePayment: res.result.min }))
		dispatch(setMaxPossiblePayment({ maxPossiblePayment: res.result.max }))
		dispatch(
			setFilters({
				min_payment: res.result.min,
				max_payment: res.result.max,
			})
		)
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Откликнуться на вакансию / контракт
export const createResponse =
	(id, type = 11) =>
	async (dispatch) => {
		const res = await responsesApi.createResponse(id, type)

		if (res.success) {
			if (type === 11) {
				dispatch(setVacancies({ vacancies: [] }))
				dispatch(requestVacancies())
				dispatch(requestVacancyById(id))
			}

			if (type === 10) {
				dispatch(setContracts({ contracts: [] }))
				dispatch(requestContracts())
				dispatch(requestContractById(id))
			}
		} else {
			dispatch(addNot({ not: res.result.msg, type: 'error' }))
		}
	}

// Отменить отклик
export const cancelResponse =
	(id, responseId, type = 11) =>
	async (dispatch) => {
		const res = await responsesApi.cancelResponse(responseId)

		if (res.success) {
			if (type === 11) {
				dispatch(setVacancies({ vacancies: [] }))
				dispatch(requestVacancies())
				dispatch(requestVacancyById(id))
			}

			if (type === 10) {
				dispatch(setContracts({ contracts: [] }))
				dispatch(requestContracts())
				dispatch(requestContractById(id))
			}
		} else {
			dispatch(addNot({ not: res.result.msg, type: 'error' }))
		}
	}

// Отправить тестовое задание
export const sendTestTask =
	(id, data, type = 11) =>
	async (dispatch) => {
		const res = await responsesApi.sendTestTask(data)

		if (res.success) {
			if (type === 11) {
				dispatch(setVacancies({ vacancies: [] }))
				dispatch(requestVacancies())
				dispatch(requestVacancyById(id))
			}

			if (type === 10) {
				dispatch(setContracts({ contracts: [] }))
				dispatch(requestContracts())
				dispatch(requestContractById(id))
			}
		} else {
			dispatch(addNot({ not: res.result.msg, type: 'error' }))
		}
	}

export default reducer
