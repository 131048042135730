/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

import certificateApi from '../../../../apps/XCluster/api/certificate-api'

import { addNot } from '../../common/global-nots/global-nots'

const initialState = {
	certificates: [],
	validCertificates: [],
	count: 0,
	limit: 20,
	offset: 0,
	isAccept: false,
}

const certificates = createSlice({
	name: 'certificates',
	initialState,
	reducers: {
		setCertificates: (state, action) => {
			state.certificates = [...state.certificates, ...action.payload.certs]
		},

		setValidCertificates: (state, action) => {
			state.validCertificates = [...action.payload.certs, ...state.validCertificates]
		},

		clearCertificates: (state) => {
			state.certificates = []
		},

		clearValidCertificates: (state) => {
			state.validCertificates = []
		},

		setCount: (state, action) => {
			state.count = action.payload.count
		},

		incOffset: (state) => {
			state.offset = state.offset + state.limit
		},

		setOffset: (state, action) => {
			state.offset = action.payload.offset
		},

		setIsAccept: (state, action) => {
			state.isAccept = action.payload.isAccept
		},
	},
})

const { reducer, actions } = certificates

export const {
	setCertificates,
	setValidCertificates,
	clearCertificates,
	clearValidCertificates,
	incOffset,
	setCount,
	setOffset,
	setIsAccept,
} = actions

// Получение списка сертификатов для пользователя
export const requestCertificates = () => async (dispatch, getState) => {
	const {
		certificates: { limit, offset },
	} = getState()

	const res = await certificateApi.requestCertificates({ limit, offset })

	if (res.success) {
		dispatch(setCertificates({ certs: res.result.certs }))
		dispatch(setCount({ count: res.result.count }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Подтвердить сертификат
export const approveCertificate = (hash, password) => async (dispatch) => {
	const res = await certificateApi.approveCertificate(hash, password)

	if (res.success) {
		dispatch(setIsAccept({ isAccept: true }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

export default reducer
