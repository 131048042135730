import React from 'react'
import PropTypes from 'prop-types'

import './SocialIconsItem.scss'

const SocialIconsItem = React.memo(({ icon, link }) => (
	<a href={link} className="join-us__icons-block-item" target="_blank" rel="noopener noreferrer">
		<i style={{ backgroundImage: `url(${icon})` }} />
	</a>
))

const { string } = PropTypes

SocialIconsItem.defaultProps = {
	icon: '',
	link: '',
}

SocialIconsItem.propTypes = {
	icon: string,
	link: string,
}

export default SocialIconsItem
