/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

import productsApi from '../../../../apps/XCluster/api/products-api'
import commonApi from '../../../../api/common-api'
import appApi from '../../../../api/app-api'

import { addNot } from '../../common/global-nots/global-nots'

const initialState = {
	productInfo: {},
	products: [],
	filters: {
		prod_type: 0,
		limit: 11,
		offset: 0,
		city: '',
		country: '',
		is_proposal: 0,
		min_price: 0,
		max_price: 0,
	},
	cities: [],
	countries: [],
	certificate: null,
	count: null,
	isLoading: false,
	minPossiblePrice: 0,
	maxPossiblePrice: 0,
	isLoaded: false,
	failed: false,
}

const products = createSlice({
	name: 'products',
	initialState,
	reducers: {
		setProducts: (state, action) => {
			state.products = action.payload.products
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setCities: (state, action) => {
			state.cities = action.payload.cities
		},

		setCountries: (state, action) => {
			state.countries = action.payload.countries
		},

		setProduct: (state, action) => {
			state.productInfo = action.payload.product
		},

		setCertificate: (state, action) => {
			state.certificate = action.payload.certificate
		},

		setProductsCount: (state, action) => {
			state.count = action.payload.count
		},

		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},

		setMaxPossiblePrice: (state, action) => {
			state.maxPossiblePrice = action.payload.maxPossiblePrice
		},

		setMinPossiblePrice: (state, action) => {
			state.minPossiblePrice = action.payload.minPossiblePrice
		},

		setIsLoaded: (state, action) => {
			state.isLoaded = action.payload.isLoaded
		},

		setFailed: (state, action) => {
			state.failed = action.payload.failed
		},
	},
})

const { reducer, actions } = products

export const {
	setProducts,
	setFilters,
	setCities,
	setCountries,
	setProduct,
	setCertificate,
	setProductsCount,
	setIsLoading,
	setMinPossiblePrice,
	setMaxPossiblePrice,
	setIsLoaded,
	setFailed,
} = actions

// получение списка товаров
export const requestProducts =
	(clean = false) =>
	async (dispatch, getState) => {
		const {
			products: { filters, products: productsList, isLoaded },
		} = getState()

		dispatch(setIsLoading({ isLoading: true }))

		const res = await productsApi.requestProducts(filters)

		if (res.success) {
			if (clean) dispatch(setProducts({ products: res.result.product }))
			else dispatch(setProducts({ products: [...productsList, ...res.result.product] }))

			dispatch(setProductsCount({ count: res.result.count }))

			if (!isLoaded) {
				dispatch(setIsLoaded({ isLoaded: true }))
			}
		} else {
			dispatch(addNot({ not: res.result.msg, type: 'error' }))
		}

		dispatch(setIsLoading({ isLoading: false }))
	}

// Получение минимальной и максимальной цены для фильтра
export const requestMinMax = () => async (dispatch) => {
	const res = await commonApi.requestMinMax(3)

	if (res.success) {
		dispatch(setMinPossiblePrice({ minPossiblePrice: res.result.min }))
		dispatch(setMaxPossiblePrice({ maxPossiblePrice: res.result.max }))
		dispatch(setFilters({ min_price: res.result.min, max_price: res.result.max }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Получение списка городов для фильтров
export const getCitiesForFilters = () => async (dispatch) => {
	const res = await commonApi.getCitiesForFilters(3)

	if (res.success) {
		dispatch(setCities({ cities: res.result }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Получение списка стран для фильтров
export const getCountriesForFilters = () => async (dispatch) => {
	const res = await commonApi.getCountriesForFilters(3)

	if (res.success) {
		dispatch(setCountries({ countries: res.result }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// получение сертификата
export const requestCertificate = (id) => async (dispatch) => {
	const res = await productsApi.requestCertificate(id)

	if (res.success) {
		dispatch(setCertificate({ certificate: res.result }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Получение товара по id
export const requestProduct = (id) => async (dispatch) => {
	const res = await productsApi.requestProduct(id)

	if (res.success) {
		dispatch(setProduct({ product: res.result }))

		if (res.result.status_props === 2) {
			dispatch(requestCertificate(res.result.props_id))
		}
	} else {
		dispatch(setFailed({ failed: true }))
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// заказ товара
export const applyProduct = (id) => async (dispatch) => {
	const res = await appApi.apply(id, 3)

	if (res.success) {
		dispatch(requestProduct(id))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// отмена заявки
export const cancelProduct = (id) => async (dispatch) => {
	const res = await appApi.cancel(id)

	if (res.success) {
		dispatch(requestProduct(id))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

export default reducer
