import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { url } from '../../../../../../../api/api'

import './IntegrationsModalTitle.scss'

const IntegrationsModalTitle = memo(({ label, icon }) => (
	<div className="integrations-modal-title">
		{label}
		<div className="title-icon">
			<i style={{ backgroundImage: `url(${url}/static/${icon})` }} />
		</div>
	</div>
))

const { string } = PropTypes

IntegrationsModalTitle.propTypes = {
	label: string.isRequired,
	icon: string.isRequired,
}

export default IntegrationsModalTitle
