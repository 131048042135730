import React, { memo } from 'react'

import './Logo.scss'

const Logo = memo(() => (
	<div className="logo">
		<i />
	</div>
))

export default Logo
