import React from 'react'

import './GeneralTerms.scss'

const GeneralTerms = React.memo(() => (
	<div className="main__general-terms">
		<div className="general-terms__body">
			<div className="general-terms__term general-terms__term--rigth-skew">Практика</div>
			<div className="general-terms__term general-terms__term--left-skew">Мероприятия</div>
			<div className="general-terms__term general-terms__term--rigth-skew">Нетворкинг</div>
			<div className="general-terms__term general-terms__term--left-skew">Коворкинг</div>
			<div className="general-terms__term general-terms__term--rigth-skew">Работа</div>
			<div className="general-terms__term general-terms__term--left-skew">Платформа</div>
		</div>
	</div>
))

export default GeneralTerms
