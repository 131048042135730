import React from 'react'

import './About.scss'

import laptop from '../../../../../../assets/media/landing/bg_abiut.png'

const About = React.memo(() => (
	<div className="about-section">
		<div className="about-section__picture-wrapper">
			<img src={laptop} alt="about-us-laptop" className="about-section__picture" />
		</div>
		<div className="about-section__text-wrapper">
			<div className="about-section__text">
				<p className="about-section__text-title title">о проекте</p>
				<p className="about-section__text-item text">
					<span className="bold">X-Сluster</span> - это сообщество единомышленников.
				</p>
				<p className="about-section__text-item text">
					{' '}
					Мы хотим, чтобы IT-специалисты развивались в соответствии с текущими перспективами и
					потребностями рынка.
				</p>
				<p className="about-section__text-item text">
					<span className="bold">
						Для этого мы проводим мероприятия, предлагаем практику и работу в IT-компаниях,
						поддерживаем проекты, предоставляем доступ к инфраструктуре сообщества.
					</span>
				</p>
			</div>
		</div>
	</div>
))

export default About
