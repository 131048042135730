import instance from '../../../api/api'

import { getToken } from '../../../functions/localStorage'

const commentsApi = {
	async requestComments(filters, targetId) {
		const response = await instance.post(
			`user/get/comment`,
			{ ...filters, targetId },
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async addComment(msg, targetId, targetType) {
		const response = await instance.post(
			`user/create/comment`,
			{ msg, targetId, targetType },
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},
}

export default commentsApi
