import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import Button from '../../../components/Buttons/Button/Button'
import Icon from '../../../components/Icon/Icon'
import Input from '../../../components/Inputs/Input/Input'

import { approveCertificate } from '../../../store/reducers/x-cluster/certificates/certificates'

import { getIsAccept } from '../../../store/reducers/x-cluster/certificates/certificates-selectors'

import ApproveIcon from '../../../assets/media/svg/actived.svg'

import './AcceptCertificatePageBody.scss'

const AcceptCertificatePageBody = () => {
	const dispatch = useDispatch()

	const {
		params: { hash },
	} = useRouteMatch()

	const isAcceptCert = useSelector(getIsAccept)

	const [password, setPassword] = useState('')

	const setPasswordHandler =
		() =>
		({ target: { value } }) =>
			setPassword(value)

	const approveCertificateHandler = () => dispatch(approveCertificate(hash, password))

	return (
		<div className="body-block">
			{isAcceptCert ? (
				<div className="body-block__accept">
					<Icon icon={ApproveIcon} size={48} />
					<p>Сертификат одобрен</p>
				</div>
			) : (
				<div className="body-block__enter-password">
					<h2 className="body-block__enter-password__header">Подтверждение сертификата</h2>

					<div className="body-block__enter-password__form">
						<Input label="Введите пароль" value={password} onChange={setPasswordHandler()} />
						<Button small label="Подтвердить" onClick={approveCertificateHandler} />
					</div>
				</div>
			)}
		</div>
	)
}

export default AcceptCertificatePageBody
