import React, { memo } from 'react'

import AcceptCertificatePageHeader from './AcceptCertificatePageHeader/AcceptCertificatePageHeader'
import AcceptCertificatePageBody from './AcceptCertificatePageBody/AcceptCertificatePageBody'

import './AcceptCertificatePage.scss'

const AcceptCertificatePage = memo(() => (
	<div className="accept-certificate-page">
		<AcceptCertificatePageHeader />

		<AcceptCertificatePageBody />
	</div>
))

export default AcceptCertificatePage
