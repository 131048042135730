import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { onPressEnter } from '../../../functions/common'

import './MobileMenuButton.scss'

const MobileMenuButton = memo(({ icon = false, toggleMobileMenu = () => {} }) => (
	<div
		className={cn('mobile-menu-button', { active: icon })}
		onClick={toggleMobileMenu}
		onKeyPress={onPressEnter(toggleMobileMenu)}
		role="button"
		tabIndex={0}
	>
		<i />
	</div>
))

MobileMenuButton.defaultProps = {
	icon: false,
}

const { bool, func } = PropTypes

MobileMenuButton.propTypes = {
	icon: bool,
	toggleMobileMenu: func.isRequired,
}

export default MobileMenuButton
