import instance from '../../../api/api'

import { getToken } from '../../../functions/localStorage'

const suggestionsApi = {
	// Запросить все предложения
	async requestOffers(data) {
		const response = await instance.post('suggest/get/all/recrut', data, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		})

		return response.data
	},

	async requestOffersById(data) {
		const response = await instance.post('suggest/get/recrut', data, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		})

		return response.data
	},

	// Запросить все рекомендации
	async requestRecommends(data) {
		const response = await instance.post('recomend/get/all/recrut', data, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		})

		return response.data
	},

	async requestRecommendsById(data) {
		const response = await instance.post('recomend/get/recrut', data, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		})

		return response.data
	},

	// Создать новое предложение (предложить кандидата)
	async createOffer(data) {
		const response = await instance.post(`suggest/create`, data, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		})

		return response.data
	},

	// Создать новую рекомендацию (рекомендовать кандидата)
	async createRecommend(data) {
		const response = await instance.post(`recomend/create`, data, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		})

		return response.data
	},

	async cancelRecruterWork(id, type) {
		const response = await instance.get(`ttwork/delete?target_id=${id}&target_type=${type}`, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		})

		return response.data
	},

	async takeToWork(id, type) {
		const response = await instance.get(`ttwork/run?target_id=${id}&target_type=${type}`, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		})

		return response.data
	},
}

export default suggestionsApi
