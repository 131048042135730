import instance from '../../../api/api'

import { getToken } from '../../../functions/localStorage'

const vacanciesApi = {
	async requestVacancies(data) {
		const response = await instance.post('vacancy/get/all/user', data, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		})

		return response.data
	},

	async requestVacancyById(id) {
		const response = await instance.get(`vacancy/get/user?vacancy_id=${id}`, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		})

		return response.data
	},
}

export default vacanciesApi
