const exceptions = [
	// PROD
	'609e3ab21fd70104d3f00d7e', // Александ Иванов
	'61e54efd6b601e2aa485071a', // Александра Кошелева
	'60e0978c38b000605f39eb8e', // Сергей Гуляев
	'609e886e6321a00743047ec4', // Вячеслав
	'609ea0006321a0074304bf48', // Егор

	// DEV
	'607fdeb841ce07ff3f8a0179', // Александ Иванов+
	'61e54f95c94aa26ce85e9e9a', // Александра Кошелева+
	'609fea1c5549b823a8deb26e', // Вячеслав Шаповалов+
	'609e886e6321a00743047ec4', // Вячеслав
	'609ea0006321a0074304bf48', // Егор
]

export default exceptions
