/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

import notificationsApi from '../../../../apps/XCluster/api/notifications-api'

import { addNot } from '../global-nots/global-nots'

const initialState = {
	notifications: [],
	respondNotifs: [],
	suggestsNotifs: [],
	recomendsNotifs: [],
	count: 0,
	respondsCount: 0,
	suggestsCount: 0,
	recomendsCount: 0,
	newCount: 0,
	limit: 30,
	offset: 0,
}

const notifications = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		setNotifications: (state, action) => {
			state.notifications = action.payload.nots
		},
		setRespondNotifs: (state, action) => {
			state.respondNotifs = action.payload.nots
		},
		setSuggestsNotifs: (state, action) => {
			state.suggestsNotifs = action.payload.nots
		},
		setRecomendsNotifs: (state, action) => {
			state.recomendsNotifs = action.payload.nots
		},
		setNewNotification: (state, action) => {
			state.notifications = [action.payload.not, ...state.notifications]
		},
		setNewRespondNotif: (state, action) => {
			state.respondNotifs = [action.payload.not, ...state.respondNotifs]
		},
		setNewSuggestsNotifs: (state, action) => {
			state.suggestsNotifs = [action.payload.not, ...state.suggestsNotifs]
		},
		setNewRecomendsNotifs: (state, action) => {
			state.recomendsNotifs = [action.payload.not, ...state.recomendsNotifs]
		},
		setCount: (state, action) => {
			state.count = action.payload.count
		},
		setRespondsCount: (state, action) => {
			state.respondsCount = action.payload.count
		},
		setSuggestsCount: (state, action) => {
			state.suggestsCount = action.payload.count
		},
		setRecomendsCount: (state, action) => {
			state.recomendsCount = action.payload.count
		},
		setNewCount: (state, action) => {
			state.newCount = action.payload.new
		},
		incNewCount: (state) => {
			state.newCount += 1
		},
	},
})

const { reducer, actions } = notifications

export const {
	setNotifications,
	setRespondNotifs,
	setNewNotification,
	setCount,
	setNewCount,
	incNewCount,
	setRespondsCount,
	setNewRespondNotif,
	setSuggestsNotifs,
	setNewSuggestsNotifs,
	setSuggestsCount,
	setRecomendsNotifs,
	setNewRecomendsNotifs,
	setRecomendsCount,
} = actions

// Получить уведомления
export const requestNotifications = () => async (dispatch, getState) => {
	const {
		notifications: { limit, offset },
	} = getState()

	const res = await notificationsApi.requestNotifications({ limit, offset })

	if (res.success) {
		dispatch(setNotifications({ nots: res.result.notifs }))
		dispatch(setCount({ count: res.result.count }))
		dispatch(setNewCount({ new: res.result.new_notif }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Получить уведомления отдельно по откликам
export const requestRespondsNotifs = () => async (dispatch) => {
	const res = await notificationsApi.requestRespondsNotifs()

	if (res.success) {
		dispatch(setRespondNotifs({ nots: res.result.target_list }))
		dispatch(setRespondsCount({ count: res.result.count }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Получить отдельные нотификации по предложениям
export const requestSuggestNotifs = () => async (dispatch) => {
	const res = await notificationsApi.requestSuggestNotifs()

	if (res.success) {
		dispatch(setSuggestsNotifs({ nots: res.result.target_list }))
		dispatch(setSuggestsCount({ count: res.result.count }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Получить отдельные нотификации по рекомендациям
export const requestRecomendsNotifs = () => async (dispatch) => {
	const res = await notificationsApi.requestRecomendsNotifs()

	if (res.success) {
		dispatch(setRecomendsNotifs({ nots: res.result.target_list }))
		dispatch(setRecomendsCount({ count: res.result.count }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Пометить уведомления как просмотренные
export const checkNots = () => async (dispatch) => {
	const res = await notificationsApi.checkNots()

	if (res.success) {
		dispatch(requestNotifications())
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Пометить уведомление по конкретному отклику как прочитанное
export const checkResponseNotif =
	({ id, type }) =>
	async (dispatch) => {
		const res = await notificationsApi.checkResponseNotif({ id, type })

		if (res.success) {
			dispatch(requestRespondsNotifs())
			dispatch(requestNotifications())
		} else {
			dispatch(addNot({ not: res.result.msg, type: 'error' }))
		}
	}

// Пометить уведомление по конкретному предложению как прочитанное
export const checkSuggestNotif =
	({ id, type }) =>
	async (dispatch) => {
		const res = await notificationsApi.checkSugestNotif({ id, type })

		if (res.success) {
			dispatch(requestSuggestNotifs())
			dispatch(requestNotifications())
		} else {
			dispatch(addNot({ not: res.result.msg, type: 'error' }))
		}
	}

// Пометить уведомление по конкретной рекомендации как прочитанное
export const checkRecomendNotif =
	({ id, type }) =>
	async (dispatch) => {
		const res = await notificationsApi.checkRecomendNotif({ id, type })

		if (res.success) {
			dispatch(requestRecomendsNotifs())
			dispatch(requestNotifications())
		} else {
			dispatch(addNot({ not: res.result.msg, type: 'error' }))
		}
	}

export default reducer
