import instance from '../../../api/api'

import { getToken } from '../../../functions/localStorage'

const searchApi = {
	async searchLang(text) {
		const response = await instance.post(
			`support/lang?pat=${text}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async searchCountry(text) {
		const response = await instance.post(
			`support/country?pat=${text}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async searchCity(text, countryId = '') {
		const response = await instance.post(
			`support/city?pat=${text}&country_id=${countryId}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},
}

export default searchApi
