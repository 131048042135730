export const setToken = (token) => localStorage.setItem('token', token)

export const getToken = () => localStorage.getItem('token')

export const removeToken = () => localStorage.removeItem('token')

export const removeInfo = () => localStorage.removeItem('info')

export const setRefFlag = (flag) => localStorage.setItem('ref', flag)

export const getRefFlag = () => localStorage.getItem('ref')

export const setFromFlag = (flag) => localStorage.setItem('from', flag)

export const getFromFlag = () => localStorage.getItem('from')

export const setPositionType = (type) => localStorage.setItem('type', type)

export const getPositionType = () => localStorage.getItem('type')

export const setPositionId = (id) => localStorage.setItem('id', id)

export const getPositionId = () => localStorage.getItem('id')

export const setPositionName = (name) => localStorage.setItem('name', name)

export const getPositionName = () => localStorage.getItem('name')
