import React, { useRef, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './Tooltip.scss'

const Tooltip = memo(({ show, children }) => {
	const tooltip = useRef()
	const angle = useRef()
	const reverseAngle = useRef()

	useEffect(() => {
		const parent = tooltip.current.parentNode
		// координаты родителя
		const coords = parent.getBoundingClientRect()
		const tooltipEl = tooltip.current
		const angleEl = angle.current
		const reverseAngleEl = reverseAngle.current

		// половина ширины родителя
		const parentHalfWidth = parent.offsetWidth / 2

		const tooltipWidth = tooltipEl.offsetWidth
		// четверть ширины подсказки для рассчета положения подсказки и уголка при левостороннем расположении
		const tooltipOffset = tooltipWidth / 2

		// рассчет расположения подсказки и уголка по горизонтали
		const left = coords.left + parentHalfWidth - tooltipOffset
		tooltipEl.style.left = `${left > 10 ? left : 10}px`
		angleEl.style.left = `${tooltipOffset - (left > 10 ? 10 : 25)}px`
		reverseAngleEl.style.left = angleEl.style.left

		// рассчет расположения подсказки по вертикали
		if (coords.top + tooltipEl.offsetHeight + 65 > document.documentElement.clientHeight) {
			tooltipEl.style.top = `${coords.top - tooltipEl.offsetHeight - 15}px`
			angleEl.style.display = 'none'
			reverseAngleEl.style.display = 'block'
		} else {
			tooltipEl.style.top = `${coords.bottom + 15}px`
			angleEl.style.display = 'block'
			reverseAngleEl.style.display = 'none'
		}
	})

	return (
		<div ref={tooltip} className={cn('tooltip', { show })}>
			<span ref={angle} className="tooltip__angle">
				▲
			</span>
			<div ref={reverseAngle} className="tooltip__angle tooltip__reverse-angle">
				▼
			</div>
			{children}
		</div>
	)
})

Tooltip.defaultProps = {
	show: false,
}

const { bool, node } = PropTypes

Tooltip.propTypes = {
	show: bool,

	children: node.isRequired,
}

export default Tooltip
