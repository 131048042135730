import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import SVG from 'react-inlinesvg'

import arrowSvg from '../../assets/media/svg/link-arrow.svg'

import './ArrowLink.scss'

const ArrowLink = memo(({ link, text, left, white }) => (
	<Link to={link} className={cn('arrow-link', { left, white })}>
		{text}

		<SVG src={arrowSvg} className="arrow-link__icon" />
	</Link>
))

ArrowLink.defaultProps = {
	text: '',
	left: false,
	white: false,
}

const { string, bool } = PropTypes

ArrowLink.propTypes = {
	link: string.isRequired,
	text: string,
	left: bool,
	white: bool,
}

export default ArrowLink
