import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './IconItem.scss'

const IconItem = React.memo(({ label, classname, position }) => (
	<div className={cn('profit-second__icon-item-wrapper', classname)}>
		<div className={cn('profit-second__icon-item', position)}>
			<div className="label subtitle">{label}</div>
			<div className="icon-wrapper">
				<i className={`icon icon-${classname}`} />
			</div>
		</div>
	</div>
))

const { string } = PropTypes

IconItem.defaultProps = {
	label: '',
	classname: '',
	position: '',
}

IconItem.propTypes = {
	label: string,
	classname: string,
	position: string,
}

export default IconItem
