import instance from '../../../api/api'

import { getToken } from '../../../functions/localStorage'

const knowledgeBaseApi = {
	async requestKnowledgeBase(filters) {
		const response = await instance.post(
			'user/search/know',
			{
				...filters,
			},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async requesArticleById(id) {
		const response = await instance.post(
			`know/get?know_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},
}

export default knowledgeBaseApi
