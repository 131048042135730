/*eslint-disable*/

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import NavItem from './NavItem/NavItem'

import './Nav.scss'

const Nav = React.memo(({ burger, classname }) => (
	<div className={cn('nav-links', classname, { burger })}>
		<NavItem anchor="#about" label="О проекте" />
		<NavItem anchor="#profit" label="Что вы получаете?" />
		<NavItem anchor="#faq" label="FAQ" />
		<NavItem anchor="#partnership" label="Стать партнером" />
		<NavItem anchor="#contacts" label="Контакты" />
	</div>
))

const { string } = PropTypes

Nav.defaultProps = {
	classname: '',
	burger: '',
}

Nav.propTypes = {
	classname: string,
	burger: string,
}

export default Nav
