import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Icon from '../../Icon/Icon'

import photoSvg from '../../../assets/media/svg/avatar-photo.svg'

import './AvatarReader.scss'

const AvatarReader = memo(({ small, image, onChange, reset }) => {
	const id = `avatar-${Math.random().toString(36).substring(2, 11)}`

	return (
		<div className={cn('avatar-file-reader', { small, image })}>
			{!small && <Icon icon={photoSvg} size={44} />}
			{small && <Icon icon={photoSvg} size={14} />}
			<input
				id={id}
				type="file"
				accept=".png, .jpg, .jpeg, .bmp"
				onChange={onChange}
				onClick={reset}
			/>

			{/* eslint-disable-next-line */}
			<label className="avatar-file-reader__label" htmlFor={id} />
		</div>
	)
})

AvatarReader.defaultProps = {
	small: false,
}

const { string, bool, func } = PropTypes

AvatarReader.propTypes = {
	small: bool,
	image: string.isRequired,
	onChange: func.isRequired,
	reset: func.isRequired,
}

export default AvatarReader
