export const getInitialized = ({ app: { initialized } }) => initialized

export const getIsLoading = ({ app: { isLoading } }) => isLoading

export const getPrivacyPolit = ({ app: { privacyPolit } }) => privacyPolit

export const getPersDataConsent = ({ app: { persDataConsent } }) => persDataConsent

export const getSpecsList = ({ app: { specsList } }) => specsList

export const getTechsList = ({ app: { techList } }) => techList

export const getCurrencyList = ({ app: { currencyList } }) => currencyList

export const getIsDesktop = ({ app: { device } }) => device === 'desktop'

export const getIsSmallDesktop = ({ app: { device } }) => device === 'small-desktop'

export const getIsNotepad = ({ app: { device } }) => device === 'notepad'

export const getIsMobile = ({ app: { device } }) => device === 'mobile'
