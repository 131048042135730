import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { onPressEnter } from '../../functions/common'

import './ActiveText.scss'

const ActiveText = memo(({ onClick, children }) => (
	<span
		className="active-text"
		onClick={onClick}
		onKeyPress={onPressEnter(onClick)}
		role="button"
		tabIndex={0}
	>
		{children}
	</span>
))

const { func, node } = PropTypes

ActiveText.propTypes = {
	onClick: func.isRequired,
	children: node.isRequired,
}

export default ActiveText
