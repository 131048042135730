import React, { memo } from 'react'
import { Link } from 'react-router-dom'

import Icon from '../../Icon/Icon'

import editSvg from '../../../assets/media/svg/edit.svg'

import './EditQuestionnaireLink.scss'

const EditQuestionnaireLink = memo(() => (
	<Link to="/form">
		<div className="edit-questionnaire-link">
			<Icon icon={editSvg} />

			<span> Редактировать анкету </span>
		</div>
	</Link>
))

export default EditQuestionnaireLink
