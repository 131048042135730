import beginners from './beginners/beginners'
import contracts from './contracts/contracts'
import vacancies from './vacancies/vacancies'
import responses from './responses/responses'
import xWorkMainPage from './main-page/main-page'
import suggestions from './suggestions/suggestions'
import partners from './partners/partners'
import teamFinder from './team-finder/team-finder'
import recomends from './recomendations/recomendations'

export default {
	beginners,
	contracts,
	vacancies,
	responses,
	xWorkMainPage,
	suggestions,
	partners,
	teamFinder,
	recomends,
}
