import React, { memo } from 'react'
import PropTypes from 'prop-types'

import './IntegrationsModalSubtitle.scss'

const IntegrationsModalSubtitle = memo(({ text }) => (
	<div className="integrations-modal-subtitle">{text}</div>
))

const { string } = PropTypes

IntegrationsModalSubtitle.propTypes = {
	text: string.isRequired,
}

export default IntegrationsModalSubtitle
