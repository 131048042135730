import instance from '../../../api/api'

import { getToken } from '../../../functions/localStorage'

const notificationsApi = {
	// все нотификации
	async requestNotifications({ limit, offset }) {
		const response = await instance.post(
			`user/get/notif?limit=${limit}&offset=${offset}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	// отдельные нотификации по откликам
	async requestRespondsNotifs() {
		const response = await instance.post(
			'respond/get/notif/responds?limit=0&offset=0',
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	// отдельные нотификации по предложениям
	async requestSuggestNotifs() {
		const response = await instance.post(
			'suggest/get/notif/suggests?limit=0&offset=0',
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	// отдельные нотификации по рекомендациям
	async requestRecomendsNotifs() {
		const response = await instance.post(
			'recomend/get/notif/recomends?limit=0&offset=0',
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	// отметить как прочитанные все нотификации
	async checkNots() {
		const response = await instance.post(
			'user/set/notif/check',
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	// отметить как прочитанную нотификацию конкретного отклика
	async checkResponseNotif({ id, type }) {
		const response = await instance.post(
			`respond/set/notif/check?id=${id}&type_notif=${type}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	// отметить как прочитанную нотификацию конкретного предложения
	async checkSugestNotif({ id, type }) {
		const response = await instance.post(
			`suggest/set/notif/check?id=${id}&type_notif=${type}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	// отметить как прочитанную нотификацию конкретной рекомендации
	async checkRecomendNotif({ id, type }) {
		const response = await instance.post(
			`/recomend/set/notif/check?id=${id}&type_notif=${type}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},
}

export default notificationsApi
