/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

import mainPageApi from '../../../../apps/XWork/api/mainpage-api'

import { addNot } from '../../common/global-nots/global-nots'

const initialState = {
	mainPageInfo: {},
	links: {
		vk: '',
		fb: '',
		yt: '',
		ig: '',
		ld: '',
	},
	isLoading: false,
	isLoaded: false,
	failed: false,
}

const xWorkMainPage = createSlice({
	name: 'xWorkMainPage',
	initialState,
	reducers: {
		setMainPageInfo: (state, action) => {
			state.mainPageInfo = action.payload.info
		},

		setLinks: (state, action) => {
			state.links = { ...state.links, ...action.payload }
		},

		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},

		setIsLoaded: (state, action) => {
			state.isLoaded = action.payload.isLoaded
		},

		setFailed: (state, action) => {
			state.failed = action.payload.failed
		},
	},
})

const { actions, reducer } = xWorkMainPage

export const { setMainPageInfo, setLinks, setIsLoading, setIsLoaded, setFailed } = actions

// Получение информации по главной странице
export const requestMainPageInfo = () => async (dispatch, getState) => {
	const {
		xWorkMainPage: { isLoaded },
	} = getState()

	dispatch(setIsLoading({ isLoading: true }))

	const res = await mainPageApi.requestMainPageInfo()

	if (res.success) {
		dispatch(setMainPageInfo({ info: res.result }))
		dispatch(
			setLinks({
				vk: res.result.vk,
				fb: res.result.fb,
				yt: res.result.yt,
				ig: res.result.ig,
				ld: res.result.ld,
			})
		)

		if (!isLoaded) {
			dispatch(setIsLoaded({ isLoaded: true }))
		}
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}

	dispatch(setIsLoading({ isLoading: false }))
}

export default reducer
