import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Button from '../../../../../../../components/Buttons/Button/Button'

import './IntegrationsModalButtons.scss'

const IntegrationsModalButtons = memo(
	({
		submitText,
		type = 'md1',
		onCancelClick = () => {},
		onSubmitClick = () => {},
		red = false,
		success = false,
		link,
	}) => (
		<div className={cn('integration-modal-content__buttons', { single: success })}>
			{success ? (
				<Button label="Готово" onClick={onCancelClick} green />
			) : (
				<>
					<Button white label="Отменить" type={type} onClick={onCancelClick} />
					{red ? (
						<Button red={red} label={submitText} type={type} onClick={onSubmitClick} />
					) : (
						<a href={link} target="_blank" rel="noopener noreferrer">
							<Button label={submitText} type={type} />
						</a>
					)}
				</>
			)}
		</div>
	)
)

IntegrationsModalButtons.defaultProps = {
	type: 'md1',
	red: false,
	success: false,
}

const { string, func, bool } = PropTypes

IntegrationsModalButtons.propTypes = {
	submitText: string.isRequired,
	type: string,
	onCancelClick: func.isRequired,
	onSubmitClick: func.isRequired,
	red: bool,
	success: bool,
	link: string.isRequired,
}

export default IntegrationsModalButtons
