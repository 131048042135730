import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import GlobalNot from './GlobalNot/GlobalNot'

import getGlobalNots from '../../store/reducers/common/global-nots/global-nots-selectors'

import './GlobalNots.scss'

const GlobalNots = memo(() => {
	const nots = useSelector(getGlobalNots)

	return (
		<div className={cn('global-nots', { visible: nots.length })}>
			{nots.map((not) => (
				<GlobalNot key={not.id} not={not} />
			))}
		</div>
	)
})

export default GlobalNots
