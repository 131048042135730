/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

import userFormApi from '../../../../apps/XCluster/api/user-form-api'
import commonApi from '../../../../api/common-api'

import { addNot } from '../../common/global-nots/global-nots'
import { setAll } from '../../common/local/local'
import { getCurrentUser } from '../../authorization/profile/profile'
import { setCountryId } from '../../x-cluster/search/search'
import getPageFields from './functions'

const initialState = {
	tel: null,
	isLoading: false,
	profile: {
		surname: '',
		name: '',
		patronymic: '',
		birthday: '',
		email: '',
		expIT: '',
		country: '',
		city: '',
		language: [],
		social: [],
		resumeLink: '',
		resumeFile: '',
		gitLink: '',
		about: '',
	},
	work: {
		workState: 0,
		organization: '',
		post: '',
		workOpen: false,
		motivation: '',
		workType: 0,
		country: [],
		city: [],
		monthCost: '',
		hourCost: '',
	},
	userSpecList: {},
	userTechList: {
		skills: {},
		other: [],
	},
	specsList: [],
	techList: [],
}

const userForm = createSlice({
	name: 'userForm',
	initialState,
	reducers: {
		setTel: (state, action) => {
			state.tel = action.payload.tel
		},

		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},

		setProfile: (state, action) => {
			state.profile = { ...state.profile, ...action.payload }
		},

		setWork: (state, action) => {
			state.work = { ...state.work, ...action.payload }
		},

		setUserTechs: (state, action) => {
			state.userTechList.skills = action.payload.tech
		},

		setUserTechsOther: (state, action) => {
			state.userTechList.other = action.payload.other
		},

		setUserSpecs: (state, action) => {
			state.userSpecList = action.payload.specs
		},

		setSpecsList: (state, action) => {
			state.specsList = action.payload.specs
		},

		setTechsList: (state, action) => {
			state.techList = action.payload.tech
		},

		setSocialList: (state, action) => {
			state.profile.social = action.payload.social
		},
	},
})

const { reducer, actions } = userForm

export const {
	setTel,
	setProfile,
	setWork,
	setSpecsList,
	setTechsList,
	setPhoto,
	setTechsCustom,
	setIsLoading,
	setUserSpecs,
	setUserTechs,
	setUserTechsOther,
	setSocialList,
} = actions

// Получение информации для анкеты
export const getInfo = () => async (dispatch) => {
	dispatch(setIsLoading({ isLoading: true }))

	const res = await userFormApi.getInfo()

	if (res.success) {
		const {
			user: {
				tel,
				first_page: firstPage,
				second_page: secondPage,
				third_page: thirdPage,
				fourth_page: fourthPage,
			},
			local,
		} = res.result

		dispatch(setAll({ local }))

		dispatch(setUserSpecs({ specs: thirdPage.specializations }))
		dispatch(setUserTechs({ tech: fourthPage.skills }))
		dispatch(setUserTechsOther({ other: Object.entries(fourthPage.other) }))

		dispatch(setTel({ tel }))

		dispatch(
			setProfile({
				surname: firstPage.surname,
				name: firstPage.name,
				patronymic: firstPage.patron,
				birthday: firstPage.birthday ? new Date(firstPage.birthday) : '',
				email: firstPage.email,
				expIT: firstPage.exp_it,
				country: firstPage.country,
				city: firstPage.city,
				language: firstPage.langs || [],
				social: firstPage.social || [],
				resumeLink: firstPage.resume_link,
				resumeFile: firstPage.resume_file,
				gitLink: firstPage.git_link,
				about: firstPage.about,
			})
		)

		dispatch(
			setWork({
				workState: secondPage.work_state,
				organization: secondPage.comp,
				post: secondPage.post,
				workOpen: secondPage.work_open,
				motivation: secondPage.motivation,
				workType: secondPage.work_type,
				country: secondPage.countries || [],
				city: secondPage.cities || [],
				monthCost: secondPage.month_cost,
				hourCost: secondPage.hour_cost,
			})
		)

		dispatch(setCountryId({ countryId: firstPage.country_id || null }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// Отправка на модерацию
const setVerif = () => async (dispatch) => {
	const res = await userFormApi.setVerif()

	if (!res.success) {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Сохранение информации в анкете
export const setInfo =
	(page, moderate = false) =>
	async (dispatch, getState) => {
		const data = getPageFields(page, getState())

		const res = await userFormApi.setInfo(page, data)

		if (res.success) {
			if (moderate) {
				await dispatch(setVerif())
			}

			if (page === 4 || moderate) {
				dispatch(getCurrentUser())
			}

			if (page === 4) {
				dispatch(addNot({ not: 'Сохранено', type: 'success' }))
			}

			dispatch(getInfo())
		} else {
			dispatch(addNot({ not: res.result.msg, type: 'error' }))
		}
	}

export const setSkillsList = () => async (dispatch) => {
	const res = await commonApi.getLocal(4)

	if (res.success) {
		dispatch(setSpecsList({ specs: res.result.specializations }))
		dispatch(setTechsList({ tech: res.result.techs }))
	} else {
		dispatch(addNot({ not: res.result.msg }))
	}
}

export default reducer
