import React from 'react'

import './ProfitThird.scss'

import profitCircle from '../../../../../../../assets/media/landing/product_cercle.png'

const ProfitThird = React.memo(() => (
	<div className="profit-third">
		<div className="profit-third__image-wrapper">
			<img src={profitCircle} alt="profit-circle" className="profit-third__image" />
		</div>
		<div className="profit-third__text-wrapper">
			<div className="profit-third__text-title subtitle">
				Получай бонусы за активность и обменивай их на реальные товары и услуги:
			</div>
			<div className="profit-third__text text">
				В X-Сluster действует своя бонусная система. За участие в мероприятиях и конкурсах, а также
				за выполнение различных заданий участникам начисляются бонусы, которые можно потратить на
				платформе в маркете.
			</div>
		</div>
	</div>
))

export default ProfitThird
