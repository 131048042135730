import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Icon from '../Icon/Icon'

import xSvg from '../../assets/media/svg/x-point.svg'

import './PointsCounter.scss'

const PointsCounter = memo(
	({ increase = false, decrease = false, white = false, count = 0, withIcon = true }) => {
		const formatedCount = count.toLocaleString('ru')

		/* eslint-disable-next-line */
		const countStr = increase ? `+${formatedCount}` : decrease ? `-${formatedCount}` : formatedCount

		return (
			<div className={cn('points-counter', { increase, decrease, white })}>
				<div className="points-counter__count">{countStr}</div>

				{withIcon && (
					<div className="points-counter__icon">
						<Icon icon={xSvg} size={20} />
					</div>
				)}
			</div>
		)
	}
)

PointsCounter.defaultProps = {
	increase: false,
	decrease: false,
	white: false,
	withIcon: false,
}

const { bool, number } = PropTypes

PointsCounter.propTypes = {
	increase: bool,
	decrease: bool,
	white: bool,
	count: number.isRequired,
	withIcon: bool,
}

export default PointsCounter
