/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

import responsesApi from '../../../../apps/XWork/api/responses-api'

import { addNot } from '../../common/global-nots/global-nots'

const initialState = {
	responses: [],
	count: 0,
	filters: {
		limit: 10,
		offset: 0,
	},
	isLoading: false,
	isLoaded: false,
	failed: false,
}

const responses = createSlice({
	name: 'responses',
	initialState,
	reducers: {
		setResponses: (state, action) => {
			state.responses = action.payload.responses
		},

		setCount: (state, action) => {
			state.count = action.payload.count
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},

		setIsLoaded: (state, action) => {
			state.isLoaded = action.payload.isLoaded
		},

		setFailed: (state, action) => {
			state.failed = action.payload.failed
		},
	},
})

const { actions, reducer } = responses

export const { setResponses, setCount, setFilters, setIsLoading, setIsLoaded, setFailed } = actions

// Получение списка вакансий
export const requestResponses =
	(clean = false) =>
	async (dispatch, getState) => {
		const {
			responses: { responses, filters, isLoaded },
		} = getState()

		dispatch(setIsLoading({ isLoading: true }))

		const res = await responsesApi.requestResponses(filters)

		if (res.success) {
			if (clean) dispatch(setResponses({ responses: res.result.responds }))
			else dispatch(setResponses({ responses: [...responses, ...res.result.responds] }))

			dispatch(setCount({ count: res.result.count }))

			if (!isLoaded) {
				dispatch(setIsLoaded({ isLoaded: true }))
			}
		} else {
			dispatch(addNot({ not: res.result.msg, type: 'error' }))
		}

		dispatch(setIsLoading({ isLoading: false }))
	}

// Отправка тестового задания
export const sendTestTask = (id, data) => async (dispatch) => {
	const res = await responsesApi.sendTestTask(data)

	if (res.success) {
		dispatch(setFilters({ offset: 0 }))
		dispatch(requestResponses(true))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Отменить отклик
export const cancelResponse = (responseId) => async (dispatch) => {
	const res = await responsesApi.cancelResponse(responseId)

	if (res.success) {
		dispatch(requestResponses(true))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

export default reducer
