import React, { memo } from 'react'
import PropTypes from 'prop-types'

import IntegrationsItems from './IntegrationsItems/IntegrationsItems'
import PanelSubtitle from '../../../../../components/Panels/Panel/PanelSubtitle/PanelSubtitle'
import PanelTitle from '../../../../../components/Panels/Panel/PanelTitle/PanelTitle'

import {
	integrationsSubtitleText,
	integrationsModalText,
	integrationsModalTitle,
} from '../constants'

const IntegrationsContent = memo(({ bots, isModal }) => (
	<div className="integrations-content">
		{isModal ? (
			<>
				<h3>{integrationsModalTitle}</h3>
				<PanelSubtitle style={{ marginBottom: '20px' }}>{integrationsModalText}</PanelSubtitle>
			</>
		) : (
			<>
				<PanelTitle text="Интеграции" />
				<PanelSubtitle style={{ marginBottom: '12px' }}>{integrationsSubtitleText}</PanelSubtitle>
			</>
		)}
		<IntegrationsItems bots={bots} />
	</div>
))

IntegrationsContent.defaultProps = {
	isModal: false,
}

const { string, number, bool, objectOf, arrayOf, oneOfType } = PropTypes

IntegrationsContent.propTypes = {
	bots: arrayOf(objectOf(oneOfType([string, number, bool]))).isRequired,
	isModal: bool,
}

export default IntegrationsContent
