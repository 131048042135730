export const generateID = (prefix = '') => `${prefix}${Math.random().toString(36).substring(7)}`

// Генерирует и форматирует дату из ISO-строки
export const createDate = (iso, locale = 'ru', options = {}) =>
	new Date(iso || new Date()).toLocaleString(locale, options)

// Генерирует и форматирует диапазон дат из ISO-строки
export const createDateRange = (from, to, locale, options) =>
	`${createDate(from, locale, options)} - ${createDate(to, locale, options)}`

export const getStatusByNumber = (statusNumber) => {
	const cases = {
		1: 'Модерация',
		2: 'Активный',
		3: 'Отклонён',
		4: 'Без анкеты',
	}

	if (cases[statusNumber]) return cases[statusNumber]

	return 'Нет статуса'
}

export const getPlugNumber = (id) =>
	(id.charCodeAt(id.length - 1) + id.charCodeAt(id.length - 2)) % 10

export const getSkillLevel = (value) => {
	const defaultObj = { text: '', className: '' }

	const cases = {
		1: { text: 'Junior', className: 'junior' },
		2: { text: 'Middle', className: 'middle' },
		3: { text: 'Senior', className: 'senior' },
	}

	if (cases[value]) return cases[value]

	return defaultObj
}

export const getLangLevel = (number) => {
	const cases = {
		0: 'A1',
		1: 'A2',
		2: 'B1',
		3: 'B2',
		4: 'C1',
		5: 'C2',
	}

	if (cases[number]) return cases[number]

	return ''
}

export const getLangName = (level) => {
	switch (level) {
		case 0:
			return 'A1'

		case 1:
			return 'A2'

		case 2:
			return 'B1'

		case 3:
			return 'B2'

		case 4:
			return 'C1'

		case 5:
			return ' C2'

		default:
			return 'Неизвестно'
	}
}

export const phoneSpaces = (tel = '') =>
	`${tel[0]} ${tel.substring(1, 4)} ${tel.substring(4, 7)} ${tel.substring(7, 9)} ${tel.substring(
		9,
		11
	)}`

export const workType = (type) => {
	const cases = {
		0: 'Офис',
		1: 'Удаленно',
		2: 'Офис, Удаленно',
	}

	return cases[type]
}

/*
	Обработчик нажатия кнопки Enter
	Нужен для дублирования функционала события onClick элементов с нестандартной ролью
*/
export const onPressEnter = (callback) => (e) => {
	if (e.charCode === 13) callback(e)
}

// "случайный" цвет для плитки
export const randomColor = (index) => {
	if (document.body.clientWidth < 767) {
		const int = index % 4

		switch (int) {
			case 0:
				return 'orange'
			case 1:
				return 'gray'
			case 2:
				return 'blue'
			case 3:
				return 'green'
			default:
				return 'gray'
		}
	} else if (document.body.clientWidth > 767) {
		const int = index % 6

		switch (int) {
			case 2:
				return 'green'
			case 5:
				return 'orange'
			default:
				return 'white'
		}
	}

	return 'white'
}
