/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

import commonApi from '../../../../api/common-api'
import contractsApi from '../../../../apps/XWork/api/contracts-api'
import responsesApi from '../../../../apps/XWork/api/responses-api'
import suggestionsApi from '../../../../apps/XWork/api/suggestions-api'

import { addNot } from '../../common/global-nots/global-nots'

const initialState = {
	contracts: [],
	count: 0,
	contractInfo: {},
	filters: {
		limit: 10,
		offset: 0,
		work_schedule: [],
		is_suitable: false,
		min_payment: 0,
		max_payment: 0,
		currency_key: '',
		skills: [],
	},
	minPossiblePayment: 0,
	maxPossiblePayment: 0,
	isLoading: false,
	isLoaded: false,
	failed: false,
}

const contracts = createSlice({
	name: 'contracts',
	initialState,
	reducers: {
		setContracts: (state, action) => {
			state.contracts = action.payload.contracts
		},

		setCount: (state, action) => {
			state.count = action.payload.count
		},

		setContractInfo: (state, action) => {
			state.contractInfo = action.payload.info
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setMinPossiblePayment: (state, action) => {
			state.minPossiblePayment = action.payload.minPossiblePayment
		},

		setMaxPossiblePayment: (state, action) => {
			state.maxPossiblePayment = action.payload.maxPossiblePayment
		},

		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},

		setIsLoaded: (state, action) => {
			state.isLoaded = action.payload.isLoaded
		},

		setFailed: (state, action) => {
			state.failed = action.payload.failed
		},
	},
})

const { actions, reducer } = contracts

export const {
	setContracts,
	setCount,
	setContractInfo,
	setFilters,
	setMinPossiblePayment,
	setMaxPossiblePayment,
	setIsLoading,
	setIsLoaded,
	setFailed,
} = actions

// Получение списка контрактов
export const requestContracts =
	(clean = false) =>
	async (dispatch, getState) => {
		const {
			contracts: { contracts, filters, isLoaded },
		} = getState()

		dispatch(setIsLoading({ isLoading: true }))

		const res = await contractsApi.requestContracts(filters)

		if (res.success) {
			if (clean) dispatch(setContracts({ contracts: res.result.contracts }))
			else dispatch(setContracts({ contracts: [...contracts, ...res.result.contracts] }))

			dispatch(setCount({ count: res.result.count }))

			if (!isLoaded) {
				dispatch(setIsLoaded({ isLoaded: true }))
			}
		} else {
			dispatch(addNot({ not: res.result.msg, type: 'error' }))
		}

		dispatch(setIsLoading({ isLoading: false }))
	}

// Получение информации о контракте по id
export const requestContractById = (id) => async (dispatch) => {
	const res = await contractsApi.requestContractById(id)

	if (res.success) {
		dispatch(setContractInfo({ info: res.result }))
	} else {
		dispatch(setFailed({ failed: true }))
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Получение минимальной и максимальной зарплаты для фильтра
export const requestMinMax = (type) => async (dispatch) => {
	const res = await commonApi.requestMinMax(10, type)

	if (res.success) {
		dispatch(setMinPossiblePayment({ minPossiblePayment: res.result.min }))
		dispatch(setMaxPossiblePayment({ maxPossiblePayment: res.result.max }))
		dispatch(setFilters({ min_payment: res.result.min, max_payment: res.result.max }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Откликнуться на контракт
export const createResponse = (id) => async (dispatch) => {
	const res = await responsesApi.createResponse(id, 10)

	if (res.success) {
		dispatch(setContracts({ contracts: [] }))
		dispatch(requestContracts())
		dispatch(requestContractById(id))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Взять контракт в работу рекрутером
export const takeToWork = (id) => async (dispatch) => {
	const res = await suggestionsApi.takeToWork(id, 10)

	if (res.success) {
		dispatch(setContracts({ contracts: [] }))
		dispatch(requestContracts())
		dispatch(requestContractById(id))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Отменить отклик
export const cancelResponse = (contractId, responseId) => async (dispatch) => {
	const res = await responsesApi.cancelResponse(responseId)

	if (res.success) {
		dispatch(setContracts({ contracts: [] }))
		dispatch(requestContracts())
		dispatch(requestContractById(contractId))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Отменить взятие в работу рекрутером
export const cancelRecruterWork = (contractId, type) => async (dispatch) => {
	const res = await suggestionsApi.cancelRecruterWork(contractId, type)

	if (res.success) {
		dispatch(setContracts({ contracts: [] }))
		dispatch(requestContracts())
		dispatch(requestContractById(contractId))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Отправить тестовое задание
export const sendTestTask = (id, data) => async (dispatch) => {
	const res = await responsesApi.sendTestTask(data)

	if (res.success) {
		dispatch(setContracts({ contracts: [] }))
		dispatch(requestContracts())
		dispatch(requestContractById(id))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

export default reducer
