import React, { memo } from 'react'
import PropTypes from 'prop-types'

import './FormGroup.scss'

const FormGroup = memo(({ children }) => <div className="form-group">{children}</div>)

const { node } = PropTypes

FormGroup.propTypes = {
	children: node.isRequired,
}

export default FormGroup
