import React, { memo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import cn from 'classnames'

import { delNot } from '../../../store/reducers/common/global-nots/global-nots'

import './GlobalNot.scss'

const GlobalNot = memo(({ not = {} }) => {
	const dispatch = useDispatch()

	const { id, text, type } = not

	const notRef = useRef()

	useEffect(() => {
		const timer = setTimeout(() => {
			notRef.current.classList.remove('visible')
		}, 5000)

		return () => {
			clearTimeout(timer)
		}
	}, [])

	useEffect(() => {
		const timer = setTimeout(() => {
			dispatch(delNot({ id }))
		}, 5150)

		return () => {
			clearTimeout(timer)
		}
	}, [dispatch, id])

	return (
		<div ref={notRef} className={cn('global-not visible', { [`${type}`]: type })}>
			{text}
		</div>
	)
})

const { string, number, objectOf, oneOfType } = PropTypes

GlobalNot.propTypes = {
	not: objectOf(oneOfType([string, number])).isRequired,
}

export default GlobalNot
