import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import Buttons from '../IntegrationsModalButtons/IntegrationsModalButtons'
import Subtitle from '../IntegrationsModalSubtitle/IntegrationsModalSubtitle'
import Title from '../IntegrationsModalTitle/IntegrationsModalTitle'

import { disableBot } from '../../../../../../../store/reducers/authorization/profile/profile'

const DisintegrationModalContent = memo(({ info, onClose }) => {
	if (!Object.keys(info).length) return null

	const dispatch = useDispatch()

	const { type, title, icon } = info

	const handleSubmit = () => {
		dispatch(disableBot(type))
		onClose()
	}

	return (
		<div className="disintegration-modal-content">
			<Title label={`Отвязать ${title}`} icon={icon} />
			<Subtitle text="Вы уверены, что хотите отменить интеграцию с ботом? Вы перестанете получать уведомления об обновлениях в вашем аккаунте" />
			<Buttons red submitText="Отвязать" onCancelClick={onClose} onSubmitClick={handleSubmit} />
		</div>
	)
})

DisintegrationModalContent.defaultProps = {
	info: {},
}

const { string, bool, func, objectOf, oneOfType } = PropTypes

DisintegrationModalContent.propTypes = {
	info: objectOf(oneOfType([string, bool])),
	onClose: func.isRequired,
}

export default DisintegrationModalContent
