import instance from '../../../api/api'

import { getToken } from '../../../functions/localStorage'

const mainPageApi = {
	async requestMainPageInfo() {
		const response = await instance.get('filling/get/work', {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		})

		return response.data
	},

	async mockRequest() {
		const response = await instance.get('/filling/get/work/mock', {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		})

		return response.data
	},
}

export default mainPageApi
