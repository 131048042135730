import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Header from '../Header/Header'
import Menu from './Menu/Menu'
import Nav from './Menu/Nav/Nav'
import ProfileBlock from '../ProfileBlock/ProfileBlock'

import {
	requestRecomendsNotifs,
	requestRespondsNotifs,
	requestSuggestNotifs,
} from '../../store/reducers/common/notifications/notifications'

import { getIsMobile } from '../../store/reducers/common/app/app-selectors'
import { getIsAuth } from '../../store/reducers/authorization/auth/auth-selectors'
import { getId, getRole } from '../../store/reducers/authorization/profile/profile-selectors'
import {
	getRecomendsCount,
	getRespondsCount,
	getSuggestsCount,
} from '../../store/reducers/common/notifications/notifications-selectors'

import clusterNav from '../../apps/XCluster/constants'
import { workNav, workNavRecruter, workPartnersNav } from '../../apps/XWork/constants'

import './AppLayout.scss'

import exceptions from '../../apps/XWork/exceptions'

const AppLayout = memo(({ children }) => {
	const { pathname } = useLocation()

	const dispatch = useDispatch()

	const mobileMenuRef = useRef()

	const isAuth = useSelector(getIsAuth)
	const isMobile = useSelector(getIsMobile)
	const role = useSelector(getRole)
	const id = useSelector(getId)

	const isAccesToXWork = exceptions.includes(id)

	// Непросмотренные отклики
	const respondsCount = useSelector(getRespondsCount)

	// Непросмотренные предложения и рекомендации
	const suggestionCount = useSelector(getSuggestsCount)
	const recomendsCount = useSelector(getRecomendsCount)

	const isForm = pathname === '/form'
	const isXWork = pathname.includes('/work')

	const [mobileMenu, setMobileMenu] = useState(false)

	const toggleMobileMenu = (state) => () => {
		setMobileMenu(state)
	}

	// Закрыть мобильное меню при клике вне его
	const handleClickOutside = useCallback(
		(e) => {
			if (
				(mobileMenuRef.current && mobileMenuRef.current.contains(e.target)) ||
				e.target.closest('.mobile-menu-button') ||
				e.target.closest('a')
			) {
				return
			}

			setMobileMenu(false)
			document.removeEventListener('mousedown', handleClickOutside)
		},
		[mobileMenuRef]
	)

	useEffect(() => {
		if (mobileMenu) {
			document.addEventListener('mousedown', handleClickOutside)
		} else {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [mobileMenu, handleClickOutside])

	useEffect(() => {
		setMobileMenu(false)
	}, [pathname])

	useEffect(() => {
		// получаем непросмотренные отклики на ворке
		// (для отображениянотификаций в меню)
		if (isXWork && role !== 3) {
			dispatch(requestRespondsNotifs())
		}
	}, [dispatch, isXWork, role])

	useEffect(() => {
		// получаем непросмотренные предложения на ворке для обычного юзера
		// (для отображениянотификаций в меню)
		if (isXWork && role === 3) {
			dispatch(requestSuggestNotifs())
			dispatch(requestRecomendsNotifs())
		}
	}, [dispatch, isXWork, role])

	return (
		<div className="app-layout">
			<Header empty={!isAuth} toggleMobileMenu={toggleMobileMenu(!mobileMenu)} icon={mobileMenu} />
			<div className="container">
				{isAuth && !isForm && (
					<Menu ref={mobileMenuRef} mobileMenu={mobileMenu}>
						<ProfileBlock />

						{!isXWork && <Nav items={clusterNav} />}

						{isXWork && !isAccesToXWork && <Nav items={clusterNav} />}

						{isXWork && role !== 3 && role !== 4 && isAccesToXWork && (
							<Nav additionalClass="x-work" items={workNav} isActiveResponds={!!respondsCount} />
						)}

						{isXWork && role === 3 && isAccesToXWork && (
							<Nav
								additionalClass="x-work-recruter"
								items={workNavRecruter}
								isActiveOffers={!!suggestionCount || !!recomendsCount}
							/>
						)}

						{isXWork && role === 4 && isAccesToXWork && (
							<Nav
								additionalClass="x-work-partners"
								items={workPartnersNav}
								isActiveResponds={!!respondsCount}
								isActiveRecomends={!!recomendsCount}
							/>
						)}

						<NavLink to="/about" className="app-side-menu__link">
							О&nbsp;сообществе <span className="nowrap">X-Cluster</span>
						</NavLink>
					</Menu>
				)}

				<main className="page-container">{children}</main>

				{isAuth && !isForm && isMobile && (
					<div className="mobile-menu-bottom">
						{!isXWork && <Nav items={clusterNav} />}
						{isXWork && <Nav items={workNav} />}
					</div>
				)}
			</div>
		</div>
	)
})

const { node } = PropTypes

AppLayout.propTypes = {
	children: node.isRequired,
}

export default AppLayout
