/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

import searchApi from '../../../../apps/XCluster/api/search-api'
import { addNot } from '../../common/global-nots/global-nots'

const initialState = {
	langText: '',
	langs: [],
	countryText: '',
	countries: [],
	cityText: '',
	cities: [],
	countryId: null,
}

const search = createSlice({
	name: 'search',
	initialState,
	reducers: {
		setLangText: (state, action) => {
			state.langText = action.payload.text
		},

		setLangs: (state, action) => {
			state.langs = action.payload.langs
		},

		setCountryText: (state, action) => {
			state.countryText = action.payload.text
		},

		setCountries: (state, action) => {
			state.countries = action.payload.countries
		},

		setCityText: (state, action) => {
			state.cityText = action.payload.text
		},

		setCities: (state, action) => {
			state.cities = action.payload.cities
		},

		setCountryId: (state, action) => {
			state.countryId = action.payload.countryId
		},
	},
})

const { reducer, actions } = search

export const {
	setLangText,
	setLangs,
	setCountryText,
	setCountries,
	setCityText,
	setCities,
	setCountryId,
} = actions

// Получение списка языков
export const searchLang = () => async (dispatch, getState) => {
	const {
		search: { langText },
	} = getState()

	const res = await searchApi.searchLang(langText)

	if (res.success) {
		dispatch(setLangs({ langs: res.result }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Получение списка стран
export const searchCountry = () => async (dispatch, getState) => {
	const {
		search: { countryText },
	} = getState()

	const res = await searchApi.searchCountry(countryText)

	if (res.success) {
		dispatch(setCountries({ countries: res.result }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Получение списка городов
export const searchCity = (id) => async (dispatch, getState) => {
	const {
		search: { cityText },
	} = getState()

	const res = await searchApi.searchCity(cityText, id)

	if (res.success) {
		dispatch(setCities({ cities: res.result }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

export default reducer
