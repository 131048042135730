import instance from '../../../api/api'

import { getToken } from '../../../functions/localStorage'

const responsesApi = {
	async requestResponses(data) {
		const response = await instance.post(`respond/get/all/user`, data, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		})

		return response.data
	},

	// type === 10 - контракт, type === 11 - вакансия
	async createResponse(id, type) {
		const response = await instance.post(
			`respond/create?target_id=${id}&type=${type}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async cancelResponse(id) {
		const response = await instance.get(`respond/cancel?respond_id=${id}`, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		})

		return response.data
	},

	async sendTestTask(data) {
		const response = await instance.post('respond/answer', data, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		})

		return response.data
	},
}

export default responsesApi
