import instance from '../../../api/api'

import { getToken } from '../../../functions/localStorage'

const mainPageApi = {
	async requestContentPage() {
		const response = await instance.post(
			`/filling/get`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},
}

export default mainPageApi
