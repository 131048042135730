import React, { memo } from 'react'
import { Route, Switch } from 'react-router-dom'

import Landing from './pages/Landing/Landing'
import RegistPage from './pages/RegistPage/RegistPage'

const Authorization = memo(() => (
	<Switch>
		<Route path="/login" render={() => <RegistPage />} />
		<Route path="/checkin" render={() => <RegistPage checkin />} />

		<Route path="*" render={() => <Landing />} />
	</Switch>
))

export default Authorization
