import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './LightButton.scss'

const LightButton = memo(({ label, red, transparent, onClick, className, image }) => (
	<button
		type="button"
		className={cn(`light-button ${className}`, { red, transparent })}
		onClick={onClick}
	>
		{image && <i style={{ backgroundImage: `url(${image})` }} />}
		{label}
	</button>
))

LightButton.defaultProps = {
	red: false,
	transparent: false,
	className: '',
	image: '',
}

const { string, bool, func } = PropTypes

LightButton.propTypes = {
	label: string.isRequired,
	red: bool,
	transparent: bool,
	onClick: func.isRequired,
	image: string,
	className: string,
}

export default LightButton
