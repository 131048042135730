import React from 'react'
import { Link } from 'react-router-dom'

import './AcceptCertificatePageHeader.scss'

const AcceptCertificatePageHeader = () => (
	<div className="header-block">
		<div className="header-block__icon">
			<Link to="/" />
		</div>
	</div>
)

export default AcceptCertificatePageHeader
