import React, { memo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'

import Cropper from 'react-easy-crop'

import AvatarReader from './AvatarReader/AvatarReader'
import Button from '../Buttons/Button/Button'
import Icon from '../Icon/Icon'
import ModalContainer from '../ModalContainer/ModalContainer'

import { addNot } from '../../store/reducers/common/global-nots/global-nots'
import { uploadPhoto } from '../../store/reducers/authorization/profile/profile'
import { logout } from '../../store/reducers/authorization/auth/auth'

import { getId } from '../../store/reducers/authorization/profile/profile-selectors'

import { url } from '../../api/api'

import { getPlugNumber, onPressEnter } from '../../functions/common'
import getCroppedImg, { avatarPlug } from './avatar-functions'

import profileSvg from '../../assets/media/svg/profile.svg'
import editSvg from '../../assets/media/svg/edit.svg'
import logoutSvg from '../../assets/media/svg/logout.svg'

import './Avatar.scss'

const Avatar = memo(({ small, large, inHeader, image }) => {
	const dispatch = useDispatch()
	const history = useHistory()

	const id = useSelector(getId)

	const [newImage, setNewImage] = useState(null)
	const [dropdown, setDropdown] = useState(false)
	const [showModal, handleShowModal] = useState(false)

	/* test */
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(1)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
	/* test */

	const imageUrl = image ? `${url}/static/${image}` : avatarPlug(getPlugNumber(id))

	const uploadAvatar = (e) => {
		const {
			target: { files },
		} = e
		const file = files[0]

		if (!file) return

		if (file.size / 1024 / 1024 > 1.5) {
			dispatch(addNot({ not: 'Cлишком большой размер фотографии', type: 'error' }))
		} else {
			const reader = new FileReader()

			reader.onload = async () => {
				await setNewImage(reader.result)
				await handleShowModal(true)
			}

			reader.onerror = () => {
				dispatch(addNot({ not: reader.error, type: 'error' }))
			}

			try {
				reader.readAsDataURL(file)
			} catch (err) {
				dispatch(addNot({ not: err, type: 'error' }))
			}
		}
	}

	const resetInputValue = (e) => {
		e.target.value = ''
		return null
	}

	// Получение координат для обреза загруженного файла
	const onCropComplete = useCallback((croppedArea, croppedPixels) => {
		setCroppedAreaPixels(croppedPixels)
	}, [])

	// Обрезка изображения и отправка его на сервер - загрузка аватара на сервер
	const uploadCroppedImage = useCallback(async () => {
		try {
			const croppedImage = await getCroppedImg(newImage, croppedAreaPixels)

			const data = new FormData()
			data.append('photo', croppedImage)

			handleShowModal(false)
			setNewImage(null)

			dispatch(uploadPhoto(data))
		} catch (e) {
			dispatch(addNot({ not: 'Что-то пошло не так...', type: 'error' }))
		}
	}, [dispatch, croppedAreaPixels, newImage])

	const onClose = () => {
		handleShowModal(false)
		setTimeout(() => {
			setNewImage(null)
		}, 1000)
	}

	const handleAvatarHover = (value) => () => {
		if (inHeader) setDropdown(value)
	}

	const handleAvatarClick = () => {
		history.push('/profile')
		setDropdown(false)
	}

	const handleEditClick = () => {
		history.push('/form')
		setDropdown(false)
	}

	const handleLogoutClick = () => {
		dispatch(logout())
	}

	return (
		<>
			<div
				className={cn('avatar', { small, large })}
				onMouseOver={handleAvatarHover(true)}
				onFocus={handleAvatarHover(true)}
				onMouseOut={handleAvatarHover(false)}
				onBlur={handleAvatarHover(false)}
			>
				{imageUrl ? (
					<div
						className={cn('avatar__image', {
							[`plug plug-${getPlugNumber(id)}`]: !image,
						})}
						style={{ backgroundImage: `url(${imageUrl})` }}
					>
						{large && (
							<AvatarReader
								small
								image={imageUrl}
								onChange={uploadAvatar}
								reset={resetInputValue}
							/>
						)}
					</div>
				) : (
					<div className="avatar__image avatar__image--empty">
						{large && <AvatarReader onChange={uploadAvatar} reset={resetInputValue} />}
					</div>
				)}

				{inHeader && (
					<div className={cn('avatar__dropdown', { show: dropdown })}>
						<ul className="dropdown__list">
							<li className="dropdown__list-item">
								<div
									onClick={handleAvatarClick}
									onKeyPress={onPressEnter(handleAvatarClick)}
									role="button"
									tabIndex={0}
								>
									<Icon icon={profileSvg} />
									Профиль
								</div>
							</li>
							<li className="dropdown__list-item">
								<div
									onClick={handleEditClick}
									onKeyPress={onPressEnter(handleEditClick)}
									role="button"
									tabIndex={0}
								>
									<Icon icon={editSvg} />
									Редактирование профиля
								</div>
							</li>
							<li className="dropdown__list-item">
								<div
									onClick={handleLogoutClick}
									onKeyPress={onPressEnter(handleLogoutClick)}
									role="button"
									tabIndex={0}
								>
									<Icon icon={logoutSvg} />
									Выйти
								</div>
							</li>
						</ul>
					</div>
				)}
			</div>

			{large && (
				<ModalContainer show={showModal} onClose={onClose}>
					<Cropper
						image={newImage}
						crop={crop}
						zoom={zoom}
						aspect={1 / 1}
						style={{
							/* stylelint-disable-next-line */
							containerStyle: {
								position: 'relative',
								width: '100%',
								height: 500,
							},
						}}
						onCropChange={setCrop}
						onCropComplete={onCropComplete}
						onZoomChange={setZoom}
					/>

					<Button
						label="Применить"
						onClick={uploadCroppedImage}
						className="save-avatar"
						disabled={!newImage}
					/>
				</ModalContainer>
			)}
		</>
	)
})

Avatar.defaultProps = {
	small: false,
	large: false,
	inHeader: false,
	image: '',
}

const { string, bool } = PropTypes

Avatar.propTypes = {
	small: bool,
	large: bool,
	inHeader: bool,
	image: string,
}

export default Avatar
