import React from 'react'
import PropTypes from 'prop-types'

import { generateID } from '../../functions/common'

import './Checkbox.scss'

const Checkbox = React.memo(({ name, value, checked, onChange }) => {
	const id = generateID('check-')

	return (
		<div className="checkbox-item">
			<input type="checkbox" id={id} name={name} onChange={onChange} checked={checked} />
			<label htmlFor={id}>
				<span className="checkbox__tick" />
				<span className="checkbox__text">{value}</span>
			</label>
		</div>
	)
})

Checkbox.defaultProps = {
	name: '',
	value: '',
}

const { string, bool, func } = PropTypes

Checkbox.propTypes = {
	name: string,
	value: string,
	checked: bool.isRequired,
	onChange: func.isRequired,
}

export default Checkbox
