/* eslint-disable */
import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import Icon from '../../Icon/Icon'

import {
	checkNots,
	requestNotifications,
} from '../../../store/reducers/common/notifications/notifications'

import {
	getNewCount,
	getNots,
} from '../../../store/reducers/common/notifications/notifications-selectors'

import { onPressEnter } from '../../../functions/common'

import { url } from '../../../api/api'

import approvedSvg from '../../../assets/media/svg/approved.svg'
import deniedSvg from '../../../assets/media/svg/denied.svg'
import notsSvg from '../../../assets/media/svg/notifications.svg'

import './Notifications.scss'

const ListItem = memo(({ text, image, success, checked, type, classes }) => (
	<div className={cn('list-item', classes, { checked })}>
		{image && <img src={`${url}/static/${image}`} alt="Фото события" />}
		{image && type === 'props' && (
			<span className="status">
				{success ? (
					<Icon icon={approvedSvg} className="approved" />
				) : (
					<Icon icon={deniedSvg} className="denied" />
				)}
			</span>
		)}
		<span className="text">{text}</span>
	</div>
))

const Notifications = memo(() => {
	const dispatch = useDispatch()

	const [hover, setHover] = useState(false)

	const notifications = useSelector(getNots)
	const newCount = useSelector(getNewCount)

	const handleHover = (state) => () => {
		setHover(state)

		if (state) {
			setTimeout(() => {
				if (newCount) {
					dispatch(checkNots())
				}
			}, 3000)
		}
	}

	const concatLink = (notType, notId) => () => `/work/${notType}/${notId}`

	useEffect(() => {
		dispatch(requestNotifications())
	}, [dispatch, newCount])

	return (
		<div
			className={cn('notif-container', { hover })}
			onMouseOver={handleHover(true)}
			onFocus={handleHover(true)}
			onMouseOut={handleHover(false)}
			onBlur={handleHover(false)}
			onClick={handleHover(!hover)}
			onKeyPress={onPressEnter(handleHover(!hover))}
			role="button"
			tabIndex={0}
		>
			<div className="notif-button">
				<Icon icon={notsSvg} />

				{!!newCount && (
					<div className="badge">
						<span>{newCount}</span>
					</div>
				)}
			</div>
			<div className="drop-card">
				<div className="scroll-wrapper">
					{!notifications.length && <div className="drop-card-plug">Нет уведомлений</div>}

					<div className="list-container">
						{notifications.map((not) => (
							<React.Fragment key={not.id}>
								{not.is_link ? (
									<Link to={concatLink(not.link_type, not.link_id)}>
										<ListItem
											classes="linked"
											image={not.image}
											text={not.msg}
											checked={not.сhecked}
											type={not.type}
											success={not.confirmation}
										/>
									</Link>
								) : (
									<ListItem
										image={not.image}
										text={not.msg}
										checked={not.сhecked}
										type={not.type}
										success={not.confirmation}
									/>
								)}
							</React.Fragment>
						))}
					</div>
				</div>
			</div>
		</div>
	)
})

const { string, bool } = PropTypes

ListItem.defaultProps = ''

ListItem.propTypes = {
	text: string.isRequired,
	image: string.isRequired,
	success: bool.isRequired,
	checked: bool.isRequired,
	type: string.isRequired,
	classes: string,
}

export default Notifications
