import instance from '../../../api/api'

import { getToken } from '../../../functions/localStorage'

const eventsApi = {
	async requestEvents(filters) {
		const response = await instance.post(
			'user/search/event',
			{
				...filters,
			},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async requestCalendar(date) {
		const response = await instance.post(
			`user/get/eventcal?date=${date}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async requestEventById(id) {
		const response = await instance.post(
			`event/get?event_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async requestEventByIdWithoutAuth(id) {
		const response = await instance.post(`event/get/notauth?event_id=${id}`)

		return response.data
	},

	async requestCertificate(id) {
		const response = await instance.post(
			`user/get/cert/props?props_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async approveCertificate(id) {
		const response = await instance.post(
			`user/set/approve/cert?props_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},
}

export default eventsApi
