import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useLocation } from 'react-router-dom'
import cn from 'classnames'

import Avatar from '../Avatar/Avatar'
import Certificates from './Certificates/Certificates'
import Logo from '../Logo/Logo'
import MobileMenuButton from '../Buttons/MobileMenuButton/MobileMenuButton'
import Notifications from './Notifications/Notifications'
import PointsCounter from '../PointsCounter/PointsCounter'
import Status from './HeaderStatus/HeaderStatus'

import { addNot } from '../../store/reducers/common/global-nots/global-nots'

import {
	getPhoto,
	getMoney,
	getIsModerating,
	getIsRejected,
	getIsEmpty,
	getIsApproved,
} from '../../store/reducers/authorization/profile/profile-selectors'
import { getIsDesktop, getIsMobile } from '../../store/reducers/common/app/app-selectors'

import './Header.scss'

const Header = memo(({ empty, toggleMobileMenu, icon }) => {
	const dispatch = useDispatch()

	const { pathname } = useLocation()

	const isForm = pathname === '/form'
	const isLogin = pathname === '/login'

	const isDesktop = useSelector(getIsDesktop)
	const isMobile = useSelector(getIsMobile)
	const isEmpty = useSelector(getIsEmpty)
	const isApproved = useSelector(getIsApproved)

	const isModerating = useSelector(getIsModerating)
	const isRejected = useSelector(getIsRejected)

	const money = useSelector(getMoney)
	const photo = useSelector(getPhoto)

	const checkLink = () => !pathname.includes('/work') && pathname !== '/campus'

	const handleClick = () => {
		if (isEmpty || !isApproved) {
			dispatch(
				addNot({
					not: 'Данная страница станет доступна после одобрения анкеты',
					type: 'info',
				})
			)
		}
	}

	return (
		<header
			className={cn('app-header', {
				hideMenu: isLogin,
				active: icon,
				empty,
			})}
		>
			{!isForm && <MobileMenuButton icon={icon} toggleMobileMenu={toggleMobileMenu} />}

			{empty && (
				<Link className="link-logo" to="/">
					<Logo />
				</Link>
			)}

			{!empty && (
				/* eslint-disable-next-line */
				<nav className={cn({ 'mobile-header-logo': isMobile })} onClick={handleClick}>
					<NavLink exact to="/" className="nav-link" isActive={checkLink}>
						<div className="x-icon x-name cluster" />
					</NavLink>
					<NavLink to="/work" className="nav-link">
						<div className="x-icon x-name work" />
					</NavLink>
					<NavLink to="/campus" className="nav-link">
						<div className="x-icon x-name campus" />
					</NavLink>
				</nav>
			)}

			{!empty && (
				<div className="info-container">
					{(isRejected || isModerating) && <Status waiting={isModerating} fail={isRejected} />}
					{isDesktop && <Certificates />}
					{!isDesktop && isMobile && <PointsCounter withIcon count={money} />}
					<Notifications />

					<Avatar small inHeader image={photo} />
				</div>
			)}
		</header>
	)
})

Header.defaultProps = {
	icon: false,
}

const { bool, func } = PropTypes

Header.propTypes = {
	empty: bool.isRequired,
	toggleMobileMenu: func.isRequired,
	icon: bool,
}

export default Header
