import React from 'react'
import cn from 'classnames'
import Proptypes from 'prop-types'

import PaginationItem from './PaginationItem/PaginationItem'

import './Pagination.scss'

const Pagination = React.memo(({ classname }) => (
	<div className={cn('pagination', classname)}>
		<PaginationItem anchor="" />
		<PaginationItem anchor="#about" />
		<PaginationItem anchor="#profit" />
		<PaginationItem anchor="#profit2" />
		<PaginationItem anchor="#profit3" />
		<PaginationItem anchor="#profit4" />
		<PaginationItem anchor="#faq" />
		<PaginationItem anchor="#partnership" />
		<PaginationItem anchor="#contacts" />
	</div>
))

const { string } = Proptypes

Pagination.defaultProps = {
	classname: '',
}

Pagination.propTypes = {
	classname: string,
}

export default Pagination
