import mainIcon from '../../assets/media/svg/nav-icons/main.svg'
import vacanciesIcon from '../../assets/media/svg/nav-icons/x-work/vacancies.svg'
import contractsIcon from '../../assets/media/svg/nav-icons/x-work/contracts.svg'
import responsesIcon from '../../assets/media/svg/nav-icons/x-work/responses.svg'
import beginnersIcon from '../../assets/media/svg/nav-icons/x-work/beginners.svg'
import offersIcon from '../../assets/media/svg/nav-icons/x-work/briliant.svg'
import partnersIcon from '../../assets/media/svg/nav-icons/x-work/partners.svg'

export const workNav = [
	{ icon: mainIcon, text: 'Главная', link: '/work' },
	{ icon: vacanciesIcon, text: 'Вакансии', link: '/work/vacancies' },
	{ icon: contractsIcon, text: 'Контракты', link: '/work/contracts' },
	{ icon: responsesIcon, text: 'Отклики', link: '/work/responses' },
	{ icon: beginnersIcon, text: 'Начинающим', link: '/work/beginners' },
	{ icon: partnersIcon, text: 'Партнерство', link: '/work/partners' },
]

export const workNavRecruter = [
	{ icon: mainIcon, text: 'Главная', link: '/work' },
	{ icon: vacanciesIcon, text: 'Вакансии', link: '/work/vacancies' },
	{ icon: contractsIcon, text: 'Контракты', link: '/work/contracts' },
	{ icon: offersIcon, text: 'Предложения', link: '/work/offers' },
]

export const workPartnersNav = [
	{ icon: mainIcon, text: 'Главная', link: '/work' },
	{ icon: vacanciesIcon, text: 'Вакансии', link: '/work/vacancies' },
	{ icon: contractsIcon, text: 'Контракты', link: '/work/contracts' },
	{ icon: responsesIcon, text: 'Отклики', link: '/work/responses' },
	{ icon: beginnersIcon, text: 'Начинающим', link: '/work/beginners' },
	{ icon: partnersIcon, text: 'Партнерство', link: '/work/partners' },
	{ icon: offersIcon, text: 'Мои рекомендации', link: '/work/recomenadions' },
]
