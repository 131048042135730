import mainIcon from '../../assets/media/svg/nav-icons/main.svg'
import newsIcon from '../../assets/media/svg/nav-icons/x-cluster/news.svg'
import eventsIcon from '../../assets/media/svg/nav-icons/x-cluster/events.svg'
import marketIcon from '../../assets/media/svg/nav-icons/x-cluster/market.svg'
import officesIcon from '../../assets/media/svg/nav-icons/x-cluster/offices.svg'
import knowledgeBaseIcon from '../../assets/media/svg/nav-icons/x-cluster/knowledge-base.svg'
import partnersIcon from '../../assets/media/svg/nav-icons/x-work/partners.svg'

const clusterNav = [
	{ icon: mainIcon, text: 'Главная', link: '/' },
	{ icon: newsIcon, text: 'Новости', link: '/news' },
	{ icon: eventsIcon, text: 'События', link: '/events' },
	{ icon: marketIcon, text: 'Маркет', link: '/market' },
	{ icon: officesIcon, text: 'Офисы', link: '/offices' },
	{ icon: knowledgeBaseIcon, text: 'База знаний', link: '/knowledge-base' },
	{ icon: partnersIcon, text: 'Партнерство', link: '/partners' },
]

export default clusterNav
