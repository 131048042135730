/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

import suggestionsApi from '../../../../apps/XWork/api/suggestions-api'

import { addNot } from '../../common/global-nots/global-nots'
import { requestContractById } from '../contracts/contracts'
import { requestVacancyById } from '../vacancies/vacancies'

const initialState = {
	recommends: [],
	offers: [],
	recCount: 0,
	offersCount: 0,
	filters: {
		limit: 10,
		offset: 0,
		pat: '',
		sort: '',
		sort_reverse: false,
	},
	options: { target_id: null, target_type: null },
	isLoading: false,
	isLoaded: false,
	failed: false,
}

const suggests = createSlice({
	name: 'suggests',
	initialState,
	reducers: {
		setRecommends: (state, action) => {
			state.recommends = action.payload.recommends
		},

		setOffers: (state, action) => {
			state.offers = action.payload.offers
		},

		setRecCount: (state, action) => {
			state.recCount = action.payload.count
		},

		setOffersCount: (state, action) => {
			state.offersCount = action.payload.count
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setOptions: (state, action) => {
			state.options = { ...state.options, ...action.payload }
		},

		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},

		setIsLoaded: (state, action) => {
			state.isLoaded = action.payload.isLoaded
		},

		setFailed: (state, action) => {
			state.failed = action.payload.failed
		},
	},
})

const { actions, reducer } = suggests

export const {
	setRecommends,
	setOffers,
	setRecCount,
	setOffersCount,
	setFilters,
	setIsLoading,
	setIsLoaded,
	setFailed,
	setOptions,
} = actions

// Получение списка рекомендаций
export const requestRecommends =
	(clear = false) =>
	async (dispatch, getState) => {
		const {
			suggestions: { recommends, filters, options, isLoaded },
		} = getState()

		const { target_id: targetId, target_type: targetType } = options

		dispatch(setIsLoading({ isLoading: true }))

		let res

		if (targetId && targetType) {
			res = await suggestionsApi.requestRecommendsById({
				...filters,
				...options,
			})
		} else {
			res = await suggestionsApi.requestRecommends(filters)
		}

		if (res.success) {
			if (clear) {
				dispatch(setRecommends({ recommends: [...res.result.recomends] }))
			} else {
				dispatch(setRecommends({ recommends: [...recommends, ...res.result.recomends] }))
			}

			dispatch(setRecCount({ count: res.result.count }))

			if (!isLoaded) {
				dispatch(setIsLoaded({ isLoaded: true }))
			}
		} else {
			dispatch(setFailed({ failed: true }))
			dispatch(addNot({ not: res.result.msg, type: 'error' }))
		}

		dispatch(setIsLoading({ isLoading: false }))
	}

// Получение списка предложений
export const requestOffers =
	(clear = false) =>
	async (dispatch, getState) => {
		const {
			suggestions: { offers, filters, options, isLoaded },
		} = getState()

		const { target_id: targetId, target_type: targetType } = options

		dispatch(setIsLoading({ isLoading: true }))

		let res

		if (targetId && targetType) {
			res = await suggestionsApi.requestOffersById({
				...filters,
				...options,
			})
		} else {
			res = await suggestionsApi.requestOffers(filters)
		}

		if (res.success) {
			if (clear) {
				dispatch(setOffers({ offers: [...res.result.suggests] }))
			} else {
				dispatch(setOffers({ offers: [...offers, ...res.result.suggests] }))
			}

			dispatch(setOffersCount({ count: res.result.count }))

			if (!isLoaded) {
				dispatch(setIsLoaded({ isLoaded: true }))
			}
		} else {
			dispatch(setFailed({ failed: true }))
			dispatch(addNot({ not: res.result.msg, type: 'error' }))
		}

		dispatch(setIsLoading({ isLoading: false }))
	}

// Создать предложение от рекрутера
export const createOffer = (data) => async (dispatch, getState) => {
	const {
		suggestions: { isLoaded },
	} = getState()

	const res = await suggestionsApi.createOffer(data)

	if (res.success) {
		if (!isLoaded) {
			dispatch(setIsLoaded({ isLoaded: true }))
		}
	} else {
		dispatch(setFailed({ failed: true }))
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Создать рекомендацию от рекрутера
export const createRecommend = (data) => async (dispatch, getState) => {
	const {
		suggestions: { isLoaded },
	} = getState()

	const res = await suggestionsApi.createRecommend(data)

	if (res.success) {
		if (!isLoaded) {
			dispatch(setIsLoaded({ isLoaded: true }))
		}
	} else {
		dispatch(setFailed({ failed: true }))
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Отказаться от работы по вакансии или контаркту
export const declinePosition = (type, id) => async (dispatch) => {
	const res = await suggestionsApi.declinePosition(id)

	if (res.success) {
		if (type === 11) {
			dispatch(requestVacancyById(id))
		} else {
			dispatch(requestContractById(id))
		}

		if (!isLoaded) {
			dispatch(setIsLoaded({ isLoaded: true }))
		}
	} else {
		dispatch(setFailed({ failed: true }))
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

export default reducer
