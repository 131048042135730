import React from 'react'

import Icons from './Icons/Icons'

import './ProfitSecond.scss'

const ProfitSecond = React.memo(() => (
	<div className="profit-second">
		<div className="profit-second__outer-circle">
			<Icons />
			<div className="profit-second__inner-circle">
				<div className="profit-second__inner-circle-title">Участвуй в закрытых мероприятиях</div>
				<div className="profit-second__inner-circle-text">
					Мы понимаем, насколько важно IT-специалистам быть в курсе событий и поддерживать общение с
					единомышленниками, поэтому для участников сообщества мы проводим закрытые мероприятия в
					офлайн и онлайн форматах.{' '}
				</div>
			</div>
		</div>
		<div className="profit-second__inner-circle-title adaptive">
			Участвуй в закрытых мероприятиях
		</div>
		<div className="profit-second__inner-circle-text adaptive">
			Мы понимаем, насколько важно IT-специалистам быть в курсе событий и поддерживать общение с
			единомышленниками, поэтому для участников сообщества мы проводим закрытые мероприятия в офлайн
			и онлайн форматах.{' '}
		</div>
		<Icons adaptive />
	</div>
))

export default ProfitSecond
