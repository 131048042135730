/*eslint-disable*/
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Button from '../Button/Button'
import NavContext from './Nav/NavContext'
import BurgerMenu from './BurgerMenu/BurgerMenu'
import Nav from './Nav/Nav'

import './Header.scss'

import logo from '../../../../../assets/media/landing/logo_color.png'
import whiteLogo from '../../../../../assets/media/landing/logo_white.png'

const Header = React.memo(({ classname }) => {
	const [isMenuShow, handleShowMenu] = useState(false)

	const history = useHistory()

	const handleLoginClick = () => {
		history.push('/login')
	}

	const handleRegClick = () => {
		history.push('/checkin')
	}

	return (
		<div className={cn('header', classname)}>
			<a href="/" className="header-logo-wrapper">
				<img className="header-logo" src={classname === 'color' ? logo : whiteLogo} alt="" />
			</a>
			<div className="header-body">
				<Nav classname={classname} />
				<div className="header-buttons">
					<Button
						label="Войти"
						onclick={handleLoginClick}
						classname="header-buttons__sign-button"
					/>
					<Button
						label="Присоединиться"
						onclick={handleRegClick}
						color={classname === 'color' ? 'blue' : 'white'}
						classname="header-buttons__join-button"
					/>
				</div>
				<button
					type="button"
					onClick={() => handleShowMenu(!isMenuShow)}
					className={cn('header-buttons__burger', { open: isMenuShow })}
				>
					<span />
				</button>
				<NavContext.Provider value={handleShowMenu}>
					<BurgerMenu isMenuShow={isMenuShow} handleShowMenu={handleShowMenu} />
				</NavContext.Provider>
			</div>
		</div>
	)
})

const { string } = PropTypes

Header.defaultProps = {
	classname: '',
}

Header.propTypes = {
	classname: string,
}

export default Header
