import React, { memo } from 'react'
import PropTypes from 'prop-types'

import './PanelTitle.scss'

const PanelTitle = memo(({ text, style, children }) => (
	<div className="panel-title" style={style}>
		<h3 className="panel-title__text">{text}</h3>
		<div className="panel-title__children">{children}</div>
	</div>
))

PanelTitle.defaultProps = {
	style: {},
	children: null,
}

const { string, node, objectOf } = PropTypes

PanelTitle.propTypes = {
	text: string.isRequired,
	style: objectOf(string),
	children: node,
}

export default PanelTitle
