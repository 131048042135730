import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import IconButton from '../Buttons/IconButton/IconButton'

import { onPressEnter } from '../../functions/common'

import closeSvg from '../../assets/media/svg/close.svg'

import './ModalContainer.scss'

const ModalContainer = memo(({ show, onClose, certificate, children, style }) => {
	if (show) {
		document.body.style.overflow = 'hidden'
	} else {
		document.body.style.overflow = null
	}

	const handleClickOutside = ({ target }) => {
		if (!target.closest('.modal-container')) onClose()
	}

	return (
		<div
			className={cn('modal-wrapper', { show, certificate })}
			onMouseDown={handleClickOutside}
			onKeyPress={onPressEnter(handleClickOutside)}
			role="button"
			tabIndex={0}
		>
			<div className="modal-container" style={style}>
				<IconButton className="btn-close" icon={closeSvg} size={32} onClick={onClose} />
				{children}
			</div>
		</div>
	)
})

ModalContainer.defaultProps = {
	show: false,
	certificate: false,
	children: null,
	style: {},
}

const { string, bool, func, node, objectOf } = PropTypes

ModalContainer.propTypes = {
	show: bool,
	onClose: func.isRequired,
	certificate: bool,
	children: node,
	style: objectOf(string),
}

export default ModalContainer
