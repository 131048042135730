import React from 'react'

import './ProfitFirst.scss'

import profitMobile from '../../../../../../../assets/media/landing/bg_mobile1.png'

const ProfitFirst = React.memo(() => (
	<div className="profit-first">
		<div className="profit-first__marquee-wrapper">
			<div className="profit-first__marquee">
				<p className="profit-first__marquee-item">что вы получаете ?</p>
				<p className="profit-first__marquee-item">что вы получаете ?</p>
				<p className="profit-first__marquee-item">что вы получаете ?</p>
				<p className="profit-first__marquee-item">что вы получаете ?</p>
			</div>
		</div>
		<div className="profit-first__text-wrapper">
			<div className="profit-first__text-title subtitle">
				Пользуйся инфраструктурой
				<br /> X-Cluster:
			</div>
			<div className="profit-first__text text">
				В X-Cluster разработана специальная платформа, где каждый участник сможет найти актуальную
				информацию о событиях сообщества, посмотреть обучающий курс, опубликовать свою работу или
				потратить бонусы в маркете.
				<p className="bold">Помимо этого, мы предлагаем участникам сообщества:</p>
				<ul>
					<li>помощь в реализации проектов</li>
					<li>доступ к офисам (рабочие места)</li>
					<li>юридическую и адвокатскую помощь</li>
				</ul>
			</div>
		</div>
		<div className="profit-first__image-wrapper">
			<img src={profitMobile} alt="profit-terms" className="profit-first__image" />
		</div>
	</div>
))

export default ProfitFirst
