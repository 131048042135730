import React, { memo } from 'react'
import Proptypes from 'prop-types'
import cn from 'classnames'

import './Button.scss'

const Button = memo(({ color, onclick, label, classname, size, icon }) => (
	<button type="button" className={cn('button', color, size, classname)} onClick={onclick}>
		<span>{label}</span>
		{!!icon && <i style={{ backgroundImage: `url(${icon})` }} />}
	</button>
))

const { func, string } = Proptypes

Button.defaultProps = {
	color: '',
	classname: '',
	size: '',
	icon: null,
}

Button.propTypes = {
	color: string,
	onclick: func.isRequired,
	label: string.isRequired,
	classname: string,
	size: string,
	icon: string,
}

export default Button
