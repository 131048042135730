import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Item from './IntegrationsItem/IntegrationsItem'

import './IntegrationsItems.scss'

const IntegrationsItems = memo(({ bots }) => (
	<div className="integrations-items">
		{bots.map((bot) => (
			<Item key={bot.title} bot={bot} />
		))}
	</div>
))

const { string, number, bool, objectOf, arrayOf, oneOfType } = PropTypes

IntegrationsItems.propTypes = {
	bots: arrayOf(objectOf(oneOfType([string, number, bool]))).isRequired,
}

export default IntegrationsItems
