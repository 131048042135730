/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

import appApi from '../../../../api/app-api'
import commonApi from '../../../../api/common-api'
import eventsApi from '../../../../apps/XCluster/api/events-api'

import { addNot } from '../../common/global-nots/global-nots'

const initialState = {
	beforeEvents: [],
	nowEvents: [],
	afterEvents: [],
	filters: {
		time: new Date(),
		date_type: 'month',
		type: 0,
		city: '',
		is_proposal: 0,
		events_type: 0,
	},
	calendarEvents: [],
	eventInfo: {},
	certificate: null,
	cities: [],
	isLoading: false,
	isLoaded: false,
	failed: false,
}

const events = createSlice({
	name: 'events',
	initialState,
	reducers: {
		setBeforeEvents: (state, action) => {
			state.beforeEvents = action.payload.events
		},

		setNowEvents: (state, action) => {
			state.nowEvents = action.payload.events
		},

		setAfterEvents: (state, action) => {
			state.afterEvents = action.payload.events
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setCalendarEvents: (state, action) => {
			state.calendarEvents = action.payload.events
		},

		setEventInfo: (state, action) => {
			state.eventInfo = action.payload.event
		},

		setCertificate: (state, action) => {
			state.certificate = action.payload.certificate
		},

		setCities: (state, action) => {
			state.cities = action.payload.cities
		},

		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},

		setIsLoaded: (state, action) => {
			state.isLoaded = action.payload.isLoaded
		},

		setFailed: (state, action) => {
			state.failed = action.payload.failed
		},

		setCertificateStatus: (state, action) => {
			state.certificate = {
				...state.certificate,
				status: action.payload.status,
			}
		},
	},
})

const { reducer, actions } = events

export const {
	setBeforeEvents,
	setNowEvents,
	setAfterEvents,
	setFilters,
	setCalendarEvents,
	setEventInfo,
	setCertificate,
	setCities,
	setIsLoading,
	setIsLoaded,
	setFailed,
	setCertificateStatus,
} = actions

// Получение списка событий
export const requestEvents = () => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const {
		events: { filters, isLoaded },
	} = getState()

	const res = await eventsApi.requestEvents(filters)

	if (res.success) {
		dispatch(setBeforeEvents({ events: res.result.before_events }))
		dispatch(setNowEvents({ events: res.result.now_events }))
		dispatch(setAfterEvents({ events: res.result.after_events }))

		if (!isLoaded) {
			dispatch(setIsLoaded({ isLoaded: true }))
		}
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// Получение информации для календаря
export const requestCalendar = (month) => async (dispatch) => {
	const res = await eventsApi.requestCalendar(month.toISOString())

	if (res.success) {
		dispatch(setCalendarEvents({ events: res.result }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Получение информации о сертификате
const requestCertificate = (id) => async (dispatch) => {
	const res = await eventsApi.requestCertificate(id)

	if (res.success) {
		dispatch(setCertificate({ certificate: res.result }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Получение информации для конкрентного события по id
export const requestEventById = (id) => async (dispatch) => {
	const res = await eventsApi.requestEventById(id)

	if (res.success) {
		dispatch(setEventInfo({ event: res.result }))

		if (res.result.status_props === 2) {
			dispatch(requestCertificate(res.result.props_id))
		}
	} else {
		dispatch(setFailed({ failed: true }))
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Получение информации для конкрентного события по id для неавторизованного пользователя
export const requestEventByIdWithoutAuth = (id) => async (dispatch) => {
	const res = await eventsApi.requestEventByIdWithoutAuth(id)

	if (res.success) {
		dispatch(setEventInfo({ event: res.result }))
	} else {
		dispatch(setFailed({ failed: true }))
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Подать заявку на участие
export const applyEvent = (id) => async (dispatch) => {
	const res = await appApi.apply(id, 2)

	if (res.success) {
		dispatch(requestEventById(id))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Отменить заявку на участие
export const cancelEvent = (id) => async (dispatch) => {
	const res = await appApi.cancel(id)

	if (res.success) {
		dispatch(requestEventById(id))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Получение списка городов для фильтров
export const getCitiesForFilters = () => async (dispatch) => {
	const res = await commonApi.getCitiesForFilters(2)

	if (res.success) {
		dispatch(setCities({ cities: res.result }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Подтвердить сертификат
export const approveCertificate = (id) => async (dispatch) => {
	const res = await eventsApi.approveCertificate(id)

	if (res.success) {
		dispatch(setCertificateStatus({ status: 2 }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

export default reducer
