import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Icon from '../../Icon/Icon'

import { onPressEnter } from '../../../functions/common'

import eyeSvg from '../../../assets/media/svg/status-icons/eye.svg'
import deniedSvg from '../../../assets/media/svg/status-icons/denied.svg'

import './HeaderStatus.scss'

const HeaderStatus = memo(({ waiting, fail }) => {
	const [hover, setHover] = useState(false)

	const statusButton = useRef()

	const handleHover = () => setHover(!hover)

	const mobileMenuHandler = useCallback(
		(e) => {
			if (
				(statusButton.current && statusButton.current.contains(e.target)) ||
				e.target.closest('.header-status')
			) {
				return
			}

			setHover(false)
			document.removeEventListener('mousedown', mobileMenuHandler)
		},
		[statusButton]
	)

	useEffect(() => {
		if (hover) {
			document.addEventListener('mousedown', mobileMenuHandler)
		} else {
			document.removeEventListener('mousedown', mobileMenuHandler)
		}
	}, [hover, mobileMenuHandler])

	return (
		<div
			className={cn('header-status', { waiting, fail }, { hover })}
			ref={statusButton}
			onClick={handleHover}
			onKeyPress={onPressEnter(handleHover)}
			role="button"
			tabIndex={0}
		>
			<div className="header-status__icon">
				{waiting && <Icon icon={eyeSvg} />}
				{fail && <Icon icon={deniedSvg} />}
			</div>

			{waiting && <span>Анкета на модерации</span>}
			{fail && <span>Анкета не прошла модерацию</span>}

			<div className="drop-card">
				{waiting && <span>Анкета на модерации</span>}
				{fail && <span>Анкета не прошла модерацию</span>}
			</div>
		</div>
	)
})

const { bool } = PropTypes

HeaderStatus.propTypes = {
	waiting: bool.isRequired,
	fail: bool.isRequired,
}

export default HeaderStatus
