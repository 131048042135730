import React, { lazy, memo, Suspense, useState } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import ModalContainer from '../ModalContainer/ModalContainer'
import Page404 from '../../pages/Page404/Page404'

import AppContext from '../../context/AppContext'

import {
	getIsEmpty,
	getIsApproved,
	getRole,
	// getId,
} from '../../store/reducers/authorization/profile/profile-selectors'

// import exceptions from '../../apps/XWork/exceptions'

// X-Cluster
const AboutPage = lazy(() => import('../../apps/XCluster/pages/AboutPage/AboutPage'))
const CertificatesPage = lazy(() =>
	import('../../apps/XCluster/pages/CertificatesPage/CertificatesPage')
)
const EventsPage = lazy(() => import('../../apps/XCluster/pages/EventsPage/EventsPage'))
const FormPage = lazy(() => import('../../apps/XCluster/pages/FormPage/FormPage'))
const CampusPage = lazy(() => import('../../apps/XCluster/pages/CampusPage/CampusPage'))
const MainPage = lazy(() => import('../../apps/XCluster/pages/MainPage/MainPage'))
const MarketPage = lazy(() => import('../../apps/XCluster/pages/MarketPage/MarketPage'))
const OfficesPage = lazy(() => import('../../apps/XCluster/pages/OfficesPage/OfficesPage'))
const KnowledgeBasePage = lazy(() =>
	import('../../apps/XCluster/pages/KnowledgeBasePage/KnowledgeBasePage')
)
const ProfilePage = lazy(() => import('../../apps/XCluster/pages/ProfilePage/ProfilePage'))
const NewsPage = lazy(() => import('../../apps/XCluster/pages/NewsPage/NewsPage'))

// X-Work
const WorkPage = lazy(() => import('../../apps/XWork/pages/MainPage/MainPage'))
const VacanciesPage = lazy(() => import('../../apps/XWork/pages/VacanciesPage/VacanciesPage'))
const ContractsPage = lazy(() => import('../../apps/XWork/pages/ContractsPage/ContractsPage'))
const ResponsesPage = lazy(() => import('../../apps/XWork/pages/ResponsesPage/ResponsesPage'))
const BeginnersPage = lazy(() => import('../../apps/XWork/pages/BeginnersPage/BeginnersPage'))
const PartnersPage = lazy(() => import('../../apps/XWork/pages/PartnersPage/PartnersPage'))
const SuggestionPage = lazy(() => import('../../apps/XWork/pages/SuggestionPage/SuggestionPage'))
// const TeamFinder = lazy(() => import('../../apps/XWork/pages/PartnersPage/TeamFinder/TeamFinder'))
const RecomendationsPage = lazy(() =>
	import('../../apps/XWork/pages/RecomendationsPage/RecomendationsPage')
)

const PagesRouter = memo(() => {
	const role = useSelector(getRole)
	const isEmpty = useSelector(getIsEmpty)
	const isApproved = useSelector(getIsApproved)
	// const id = useSelector(getId)

	const [confirmModal, setConfirmModal] = useState(false)
	const [confirmModalContent, setConfirmModalContent] = useState(null)

	// const isAccesToXWork = exceptions.includes(id)

	const handleConfirmModal =
		(state, content = null) =>
		() => {
			setConfirmModal(state)
			if (state) {
				setConfirmModalContent(content)
			} else {
				setTimeout(() => {
					setConfirmModalContent(null)
				}, 300)
			}
		}

	if (isEmpty || !isApproved)
		return (
			<Suspense fallback={<div />}>
				<Switch>
					<Route exact path="/" render={() => <ProfilePage />} />
					<Route path="/form" render={() => <FormPage />} />
					<Route path="/about" render={() => <AboutPage />} />
					<Redirect from="*" to="/" />
				</Switch>
			</Suspense>
		)

	return (
		<>
			<AppContext.Provider value={{ setConfirmConfig: handleConfirmModal }}>
				<Suspense fallback={<div />}>
					<Switch>
						<Route path="/campus" render={() => <CampusPage />} />

						{/* cluster */}
						<Route exact path="/" render={() => <MainPage />} />
						<Route path="/events/:id?" render={() => <EventsPage />} />
						<Route path="/market/:id?" render={() => <MarketPage />} />
						<Route path="/offices/:id?" render={() => <OfficesPage />} />
						<Route path="/knowledge-base/:id?" render={() => <KnowledgeBasePage />} />
						<Route path="/profile" render={() => <ProfilePage />} />
						<Route path="/form" render={() => <FormPage />} />
						<Route path="/certificates/:id?" render={() => <CertificatesPage />} />
						<Route path="/about" render={() => <AboutPage />} />
						<Route path="/news/:id?" render={() => <NewsPage />} />
						<Route path="/partners" render={() => <PartnersPage />} />
						{/* / cluster */}

						{/* work */}
						{role !== 3 && (
							//  && isAccesToXWork
							<Route path="/work/responses" render={() => <ResponsesPage />} />
						)}

						{role !== 3 && (
							// && isAccesToXWork
							<Route path="/work/beginners/:id?" render={() => <BeginnersPage />} />
						)}

						{role === 4 && (
							// && isAccesToXWork
							<Route path="/work/recomenadions" render={() => <RecomendationsPage />} />
						)}

						<Route exact path="/work" render={() => <WorkPage />} />

						{/* {isAccesToXWork && ( */}
						<Route path="/work/vacancies/:id?" render={() => <VacanciesPage />} />
						{/* )} */}

						{/* {isAccesToXWork && ( */}
						<Route path="/work/contracts/:id?" render={() => <ContractsPage />} />
						{/* )} */}

						{/* {isAccesToXWork && ( */}
						<Route path="/work/offers/:type?" render={() => <SuggestionPage />} />
						{/* )} */}
						{/* {isAccesToXWork &&  */}
						<Route path="/work/partners" render={() => <PartnersPage />} />
						{/* } */}
						{/* <Route path="/work/partners/team-finder" render={() => <TeamFinder />} /> */}
						{/* / work */}

						<Redirect from="/login" to="/" />
						<Redirect from="/checkin" to="/" />
						<Route path="*" render={() => <Page404 />} />
					</Switch>
				</Suspense>
			</AppContext.Provider>

			<ModalContainer show={confirmModal} onClose={handleConfirmModal(false)}>
				{confirmModalContent}
			</ModalContainer>
		</>
	)
})

export default PagesRouter
