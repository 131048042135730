import React from 'react'
import { useHistory } from 'react-router-dom'

import Button from '../../../Button/Button'

import './ButtonsBlock.scss'

import arrow from '../../../../../../../assets/media/landing/icons/button_arrow.svg'
import arrowActive from '../../../../../../../assets/media/landing/icons/button_arrow-active.svg'

const ButtonsBlock = React.memo(() => {
	const history = useHistory()

	const handleLoginClick = () => {
		history.push('/login')
	}

	// const handleInfoStuctureClick = () => {
	// 	history.push('/#about')
	// }

	return (
		<div className="main__buttons-block">
			<Button
				size="big"
				color="blue"
				label="Инфраструктура"
				icon={arrowActive}
				// onclick={handleInfoStuctureClick}
				classname="main__buttons-block-item active"
			/>
			<Button
				size="big"
				label="Мероприятия"
				onclick={handleLoginClick}
				icon={arrow}
				classname="main__buttons-block-item"
			/>
			<Button
				size="big"
				label="Обучение"
				onclick={handleLoginClick}
				icon={arrow}
				classname="main__buttons-block-item"
			/>
			<Button
				size="big"
				label="Работа"
				onclick={handleLoginClick}
				icon={arrow}
				classname="main__buttons-block-item"
			/>
			<Button
				size="big"
				label="Бонусы"
				onclick={handleLoginClick}
				icon={arrow}
				classname="main__buttons-block-item"
			/>
		</div>
	)
})

export default ButtonsBlock
