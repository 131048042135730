export const getNots = ({ notifications: { notifications } }) => notifications

export const getNewCount = ({ notifications: { newCount } }) => newCount

export const getRespondNotifs = ({ notifications: { respondNotifs } }) => respondNotifs

export const getRespondsCount = ({ notifications: { respondsCount } }) => respondsCount

export const getSuggestNotifs = ({ notifications: { suggestsNotifs } }) => suggestsNotifs

export const getSuggestsCount = ({ notifications: { suggestsCount } }) => suggestsCount

export const getRecomendsNotifs = ({ notifications: { recomendsNotifs } }) => recomendsNotifs

export const getRecomendsCount = ({ notifications: { recomendsCount } }) => recomendsCount
