import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import cn from 'classnames'

import './PaginationItem.scss'

const PaginationItem = React.memo(({ anchor }) => {
	const [active, setActive] = useState(false)

	const location = useLocation()
	const { hash } = location

	useEffect(() => {
		if (hash === anchor) setActive(true)
		else setActive(false)
	}, [hash, anchor])

	return (
		<div className="pagination-item-wrapper">
			<a href={`/${anchor}`} className={cn('pagination-item', { active })}>
				<span />
			</a>
		</div>
	)
})

const { string } = PropTypes

PaginationItem.defaultProps = {
	anchor: '',
}

PaginationItem.propTypes = {
	anchor: string,
}

export default PaginationItem
