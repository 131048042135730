import instance from '../../../api/api'

import { getToken } from '../../../functions/localStorage'

const userFormApi = {
	async getInfo() {
		const response = await instance.post(
			'/user/get/info',
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},

	async setInfo(page, data) {
		const response = await instance.post(`/user/set/info?page=${page}`, data, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		})

		return response.data
	},

	async setVerif() {
		const response = await instance.post(
			'user/set/verif',
			{},
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			}
		)

		return response.data
	},
}

export default userFormApi
