import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import cn from 'classnames'

import NavContext from '../NavContext'

import './NavItem.scss'

export const NavItem = React.memo(({ anchor, label }) => {
	const [active, setActive] = useState(false)

	const handleShowMenu = useContext(NavContext)

	const handleModileNavClick = () => {
		if (handleShowMenu) {
			handleShowMenu(false)
		}
	}

	const location = useLocation()
	const { hash } = location

	useEffect(() => {
		if (hash.replace(/[0-9]/g, '') === anchor) setActive(true)
		else setActive(false)
	}, [hash, anchor])

	return (
		<div
			tabIndex={0}
			role="button"
			onKeyPress={handleModileNavClick}
			className="nav-link-wrapper"
			onClick={handleModileNavClick}
		>
			<a href={`/${anchor}`} className={cn('nav-link', { active })}>
				{label}
			</a>
			<div className="nav-link__active-marker" />
		</div>
	)
})
const { string } = PropTypes

NavItem.defaultProps = {
	anchor: '',
	label: '',
}

NavItem.propTypes = {
	anchor: string,
	label: string,
}

export default NavItem
