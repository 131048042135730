import aboutClub from './about/about'
import certificates from './certificates/certificates'
import comments from './comments/comments'
import events from './events/events'
import knowledgeBase from './knowledge-base/knowledge-base'
import mainPage from './main-page/main-page'
import news from './news/news'
import offices from './offices/offices'
import products from './products/products'
import search from './search/search'
import userForm from './user-form/user-form'

export default {
	aboutClub,
	certificates,
	comments,
	events,
	knowledgeBase,
	mainPage,
	news,
	offices,
	products,
	search,
	userForm,
}
