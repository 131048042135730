import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './RegistForm.scss'

const RegistForm = memo(({ checkin, children }) => (
	<div className={cn('regist-form', { checkin })}>{children}</div>
))

RegistForm.defaultProps = {
	checkin: false,
}

const { bool, node } = PropTypes

RegistForm.propTypes = {
	checkin: bool,
	children: node.isRequired,
}

export default RegistForm
