import instance from '../../../api/api'

import { getToken } from '../../../functions/localStorage'

const contractsApi = {
	async requestContracts(data) {
		const response = await instance.post('contract/get/all/user', data, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		})

		return response.data
	},

	async requestContractById(id) {
		const response = await instance.get(`contract/get/user?contract_id=${id}`, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
		})

		return response.data
	},
}

export default contractsApi
