/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

import recomendationsApi from '../../../../apps/XWork/api/recomendations-api'

import { addNot } from '../../common/global-nots/global-nots'

const initialState = {
	recomends: [],
	count: 0,
	filters: {
		limit: 10,
		offset: 0,
	},
	isLoading: false,
}

const recomends = createSlice({
	name: 'recomends',
	initialState,
	reducers: {
		setRecomends: (state, action) => {
			state.recomends = action.payload.recomends
		},

		setCount: (state, action) => {
			state.count = action.payload.count
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},
	},
})

const { actions, reducer } = recomends

export const { setRecomends, setCount, setFilters, setIsLoading } = actions

// Получение списка вакансий
export const requestRecomends = () => async (dispatch, getState) => {
	const {
		recomends: { recomends, filters },
	} = getState()

	dispatch(setIsLoading({ isLoading: true }))

	const res = await recomendationsApi.requestRecomends(filters)

	if (res.success) {
		// if (clean) dispatch(setRecomends({ recomends: res.result.recomends.suggests }))
		// else
		dispatch(setRecomends({ recomends: [...recomends, ...res.result.recomends] }))

		dispatch(setCount({ count: res.result.count }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}

	dispatch(setIsLoading({ isLoading: false }))
}

export default reducer
