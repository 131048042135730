import React from 'react'

import FaqItem from './FaqItem/FaqItem'

import './Faq.scss'

const Faq = React.memo(() => (
	<div className="landing-faq">
		<div className="landing-faq__title title">Часто задаваемые вопросы</div>
		<div className="landing-faq__faq">
			<FaqItem question="Как попасть в сообщество? ">
				Мы всегда открыты к новым идеям и предложениям. Если ты эксперт в какой-то области и хочешь
				поделиться своими знаниями, пиши нам в Telegram (здесь будет активная ссылка на акк админа),
				мы поможем в организации мероприятия.
			</FaqItem>
			<FaqItem question="Зачем интегрироваться с ботом? ">
				Мы всегда открыты к новым идеям и предложениям. Если ты эксперт в какой-то области и хочешь
				поделиться своими знаниями, пиши нам в Telegram (здесь будет активная ссылка на акк админа),
				мы поможем в организации мероприятия.
			</FaqItem>
			<FaqItem question="Как с помощью сообщества найти работу?  ">
				Мы всегда открыты к новым идеям и предложениям. Если ты эксперт в какой-то области и хочешь
				поделиться своими знаниями, пиши нам в Telegram (здесь будет активная ссылка на акк админа),
				мы поможем в организации мероприятия.
			</FaqItem>
			<FaqItem question="Могу ли я стать организатором IT&nbsp;-&nbsp;мероприятия? ">
				Мы всегда открыты к новым идеям и предложениям. Если ты эксперт в какой-то области и хочешь
				поделиться своими знаниями, пиши нам в Telegram (здесь будет активная ссылка на акк админа),
				мы поможем в организации мероприятия.
			</FaqItem>
		</div>
		<div className="landing-faq__aniqestions">
			<div className="landing-faq__aniqestions-text-wrapper">
				<p className="landing-faq__aniqestions-text-title">У вас остались вопросы?</p>
				<p className="landing-faq__aniqestions-text">
					Свяжитесь с нами, и мы обязательно на них ответим
				</p>
			</div>
			<div className="landing-faq__aniqestion-button">
				<a
					href="https://t.me/xcluster_admin"
					className="button white"
					target="_blank"
					rel="noopener noreferrer"
				>
					Написать
				</a>
			</div>
		</div>
	</div>
))

export default Faq
