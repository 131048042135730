import React, { memo } from 'react'
import PropTypes from 'prop-types'

import './FormHeader.scss'

const FormHeader = memo(({ text, subText }) => (
	<header className="form-header">
		<h1>{text}</h1>
		{subText && <p>{subText}</p>}
	</header>
))

FormHeader.defaultProps = {
	subText: '',
}

const { string } = PropTypes

FormHeader.propTypes = {
	text: string.isRequired,
	subText: string,
}

export default FormHeader
