import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import cn from 'classnames'

import Icon from '../../Icon/Icon'

import { getValidCertificates } from '../../../store/reducers/x-cluster/certificates/certificates-selectors'

import { clearValidCertificates } from '../../../store/reducers/x-cluster/certificates/certificates'

import { url } from '../../../api/api'

import certSvg from '../../../assets/media/svg/certificates.svg'

import './Certificates.scss'

const ListItem = memo(({ text, code, image }) => (
	<div className="list-item">
		{image && <img src={`${url}/static/${image}`} alt="Фото события" />}
		<div className="desc">
			<div className="text">{text}</div>
			<div className="code-wrapper">
				<span className="label">код</span>
				<span className="code">{code}</span>
			</div>
		</div>
	</div>
))

const Certificates = memo(() => {
	const dispatch = useDispatch()

	const [hover, setHover] = useState(false)

	const { pathname } = useLocation()
	const path = pathname.slice(1, 13)

	const certs = useSelector(getValidCertificates)

	const certsLength = certs.length

	const handleHover = (state) => () => {
		if (path === 'certificates') return

		setHover(state)
	}

	useEffect(
		() => () => {
			dispatch(clearValidCertificates())
		},
		[dispatch]
	)

	return (
		<div
			className={cn('certif-container', { hover })}
			onMouseOver={handleHover(true)}
			onFocus={handleHover(true)}
			onMouseOut={handleHover(false)}
			onBlur={handleHover(false)}
		>
			<Link to="/certificates" onClick={handleHover(false)}>
				<div className="certif-button">
					<Icon icon={certSvg} />

					{!!certsLength && (
						<div className="badge">
							<span>{certsLength}</span>
						</div>
					)}
				</div>
			</Link>

			<div className="drop-card">
				<div className="scroll-wrapper">
					{!!certsLength && <div className="drop-card-header">Действующие сертификаты</div>}

					{!certsLength && <div className="drop-card-plug">Нет действующих сертификатов</div>}

					<div className="list-container">
						{certs.map((cert) => (
							<Link
								to={`/certificates/${cert.id}`}
								key={cert.id}
								onClick={handleHover(false)}
								className="list-item--link"
							>
								<ListItem image={cert.image} text={cert.name} code={cert.code} />
							</Link>
						))}
					</div>
				</div>
			</div>
		</div>
	)
})

const { string } = PropTypes

ListItem.propTypes = {
	text: string.isRequired,
	code: string.isRequired,
	image: string.isRequired,
}

export default Certificates
