import React, { memo } from 'react'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'
import cn from 'classnames'

import './Icon.scss'

const Icon = memo(({ icon, size, className }) => (
	<div className={cn('icon', className)} style={{ height: size, width: size }}>
		<SVG src={icon} />
	</div>
))

Icon.defaultProps = {
	size: 24,
	className: '',
}

const { string, number } = PropTypes

Icon.propTypes = {
	icon: string.isRequired,
	size: number,
	className: string,
}

export default Icon
