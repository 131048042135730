import React, { memo } from 'react'

import ArrowLink from '../../components/ArrowLink/ArrowLink'

import './Page404.scss'

const Page404 = memo(() => (
	<div className="page-404">
		<div className="page-404__header">
			<div className="page-404__header__logo" />
		</div>

		<div className="page-404__left-column">
			<div className="page-404__left-column__vincent" />
		</div>

		<div className="page-404__right-column">
			<div className="page-404__right-column__title">
				<span>404</span>
			</div>

			<div className="page-404__right-column__subtitle">
				<span>Не удалось найти страницу</span>
			</div>

			<div className="page-404__right-column__text">
				<span>Тут небольшей текст описывающий возможные причины ошибки. В две строки</span>
			</div>

			<ArrowLink link="/" text="На главную" />
		</div>
	</div>
))

export default Page404
