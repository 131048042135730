import React, { memo } from 'react'
import PropTypes from 'prop-types'

import './PanelSubtitle.scss'

const PanelSubtitle = memo(({ style, children }) => (
	<div className="panel-subtitle" style={style}>
		{children}
	</div>
))

PanelSubtitle.defaultProps = {
	style: {},
}

const { string, node, objectOf } = PropTypes

PanelSubtitle.propTypes = {
	style: objectOf(string),
	children: node.isRequired,
}

export default PanelSubtitle
