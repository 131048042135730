/*eslint-disable*/

import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Nav from '../Nav/Nav'
import Button from '../../Button/Button'

import './BurgerMenu.scss'

const BurgerMenu = React.memo(({ isMenuShow }) => {
	const history = useHistory()

	const handleLoginClick = () => {
		history.push('/login')
	}

	const handleRegClick = () => {
		history.push('/checkin')
	}
	return (
		<div className={cn('burger-menu', { show: isMenuShow })}>
			<Nav burger />
			<Button label="Войти" onclick={handleLoginClick} />
			<Button label="Присоединиться" onclick={handleRegClick} color="blue" />
		</div>
	)
})

const { bool } = PropTypes

BurgerMenu.defaultProps = {
	isMenuShow: false,
}

BurgerMenu.propTypes = {
	isMenuShow: bool,
}

export default BurgerMenu
