import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { NavLink } from 'react-router-dom'

import './NavItem.scss'

const NavItem = memo(
	({
		index,
		icon,
		text,
		link,
		exact,
		additionalClass,
		isActiveResponds,
		isActiveOffers,
		isActiveRecomends,
	}) => {
		const style = {
			backgroundImage: `url(${icon})`,
		}

		const isRespondsNotifActive = index === 4 && isActiveResponds
		const isOffersNotifActive = index === 4 && isActiveOffers
		const isRecomendsNotifActive = index === 7 && isActiveRecomends

		return (
			<NavLink
				className={cn(`nav-item nav-item--color-${index}`, additionalClass, {
					'notif-active': isRespondsNotifActive || isOffersNotifActive || isRecomendsNotifActive,
				})}
				activeClassName="nav-item--active"
				exact={exact}
				to={link}
			>
				<div className="icon">
					<i style={style} />
				</div>
				<span>{text}</span>
			</NavLink>
		)
	}
)

NavItem.defaultProps = {
	exact: false,
	additionalClass: '',
}

const { string, number, bool } = PropTypes

NavItem.propTypes = {
	index: number.isRequired,
	icon: string.isRequired,
	text: string.isRequired,
	link: string.isRequired,
	isActiveResponds: bool.isRequired,
	exact: bool,
	additionalClass: string,
	isActiveOffers: bool.isRequired,
	isActiveRecomends: bool.isRequired,
}

export default NavItem
