import React, { useCallback, useEffect, useState } from 'react'
import { Route, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'

import AcceptCertificatePage from './pages/AcceptCertificatePage/AcceptCertificatePage'
import AppLayout from './components/AppLayout/AppLayout'
import Authorization from './apps/Authorization/Authorization'
import BotsIntegrations from './apps/XCluster/components/BotsIntegrations/BotsIntegrations'
import GlobalNots from './components/GlobalNots/GlobalNots'
import PagesRouter from './components/PagesRouter/PagesRouter'

import {
	requestPersDataConsent,
	requestPrivacyPolit,
	setDevice,
	connectWs,
	initialize,
	requestValidCertificates,
	requesSkillsList,
	sendRefsConversion,
	requestCurrency,
} from './store/reducers/common/app/app'
import { requestBots } from './store/reducers/authorization/profile/profile'
import { getInfo } from './store/reducers/x-cluster/user-form/user-form'
import { requestMinMax as requestProductsMinMax } from './store/reducers/x-cluster/products/products'
import { requestMinMax as requestVacanciesMinMax } from './store/reducers/x-work/vacancies/vacancies'
import { requestMinMax as requestContractsMinMax } from './store/reducers/x-work/contracts/contracts'
import { requestMinMax as requestBeginnersMinMax } from './store/reducers/x-work/beginners/beginners'

import { getInitialized, getIsLoading } from './store/reducers/common/app/app-selectors'
import {
	getIsUserHaveBot,
	getId,
	getSurname,
	getName,
	getEmail,
	getIsModerating,
	getIsApproved,
	getBots,
	getIsBotsLoading,
	getIsEmpty,
} from './store/reducers/authorization/profile/profile-selectors'
import { getIsAuth } from './store/reducers/authorization/auth/auth-selectors'

import { getFromFlag, getRefFlag, setFromFlag, setRefFlag } from './functions/localStorage'

import './assets/styles/index.scss'
import './App.scss'

const App = () => {
	const dispatch = useDispatch()

	const location = useLocation()

	const [isBotsModal, setIsBotsModal] = useState(false)
	const bots = useSelector(getBots)
	const isBotsLoading = useSelector(getIsBotsLoading)

	const initialized = useSelector(getInitialized)

	const isLoading = useSelector(getIsLoading)
	const isAuth = useSelector(getIsAuth)
	const isEmpty = useSelector(getIsEmpty)

	const isModerating = useSelector(getIsModerating)
	const isApproved = useSelector(getIsApproved)

	const isUserHaveBot = useSelector(getIsUserHaveBot)

	const userId = useSelector(getId)
	const name = useSelector(getName)
	const surname = useSelector(getSurname)
	const mail = useSelector(getEmail)

	// данные для smartsupp
	const userInfo = JSON.stringify({ name, surname, mail })
	if (name && surname && mail) localStorage.setItem('info', userInfo)

	// Получение диапазонов для фильтров
	const requestMinMax = () => {
		dispatch(requestProductsMinMax())
		dispatch(requestVacanciesMinMax('rub'))
		dispatch(requestContractsMinMax('rub'))
		dispatch(requestBeginnersMinMax('rub'))
	}

	useEffect(() => {
		dispatch(initialize())
	}, [dispatch])

	useEffect(() => {
		if (initialized) {
			dispatch(requestPrivacyPolit())
			dispatch(requestPersDataConsent())
			dispatch(requesSkillsList())
			dispatch(requestCurrency())

			if (isAuth) {
				dispatch(connectWs())
				dispatch(requestValidCertificates())

				if (!isEmpty) {
					dispatch(getInfo())
					requestMinMax()
				}
			}
		}
	}, [dispatch, initialized, isAuth])

	/*
		Аналитика переходов и запись откуда пришел пользователь
	*/
	useEffect(() => {
		if (initialized) {
			const linkParams = location.search
			const { referrer } = document

			const refFlag = getRefFlag()
			const fromFlag = getFromFlag()

			if (!!linkParams.length && (!refFlag || !fromFlag)) {
				const data = new FormData()

				data.append('ref', linkParams)
				data.append('from', referrer)

				setRefFlag(linkParams)
				setFromFlag(referrer)

				dispatch(sendRefsConversion(data))
			}
		}
	}, [dispatch, initialized, location])

	const getDeviceStr = (width) => {
		if (width >= 1200) return 'desktop'

		if (width >= 1024) return 'small-desktop'

		if (width >= 768) return 'notepad'

		return 'mobile'
	}

	const setDeviceValue = useCallback(
		({ target: { innerWidth } }) => {
			dispatch(setDevice({ device: getDeviceStr(innerWidth) }))
		},
		[dispatch]
	)

	useEffect(() => {
		dispatch(setDevice({ device: getDeviceStr(document.documentElement.offsetWidth) }))

		window.addEventListener('resize', setDeviceValue)

		return () => window.removeEventListener('resize', setDeviceValue)
	}, [setDeviceValue])

	/*
		Открыть или закрыть окно, требующее интеграции ботов
	*/
	useEffect(() => {
		if (isAuth && !isUserHaveBot && (isModerating || isApproved)) {
			dispatch(requestBots())
			setIsBotsModal(true)
		} else {
			setIsBotsModal(false)
		}
	}, [dispatch, isModerating, isApproved, isAuth, isUserHaveBot, userId])

	if (!initialized || isLoading) return null

	return (
		<div className="app">
			{location.pathname.includes('accept-cert') && (
				<Route path="/accept-cert/:hash?" render={() => <AcceptCertificatePage />} />
			)}

			{!isAuth && <Authorization />}

			{isAuth && (
				<AppLayout>
					<PagesRouter />
					<BotsIntegrations bots={bots} showModal={!isBotsLoading && isBotsModal} isModal />
				</AppLayout>
			)}

			<GlobalNots />

			{isModerating && (
				<Helmet>
					<script type="text/javascript">
						{`!function(){
							var t=document.createElement("script");
							t.type="text/javascript",
							t.async=!0,
							t.src="https://vk.com/js/api/openapi.js?169",
							t.onload=function(){
								VK.Retargeting.Init("VK-RTRG-1024680-fCZcM"),
								VK.Retargeting.Hit()
							},
							document.head.appendChild(t)
						}();`}
					</script>
					<noscript>
						{`<img
							src="https://vk.com/rtrg?p=VK-RTRG-1024680-fCZcM"
							style="position:fixed; left:-999px;"
							alt=""
						/>`}
					</noscript>
				</Helmet>
			)}

			{isUserHaveBot && (
				<Helmet>
					<script type="text/javascript">
						{`!function(){
						var t=document.createElement("script");
						t.type="text/javascript",
						t.async=!0,
						t.src="https://vk.com/js/api/openapi.js?169",
						t.onload=function(){
							VK.Retargeting.Init("VK-RTRG-1024682-hzw7q"),
							VK.Retargeting.Hit()
						},
						document.head.appendChild(t)
					}();`}
					</script>
					<noscript>
						{`<img
							src="https://vk.com/rtrg?p=VK-RTRG-1024682-hzw7q"
							style="position:fixed; left:-999px;"
							alt=""
						/>`}
					</noscript>
				</Helmet>
			)}

			{isApproved && (
				<Helmet>
					<script type="text/javascript">
						{`!function(){
						var t=document.createElement("script");
						t.type="text/javascript",
						t.async=!0,
						t.src="https://vk.com/js/api/openapi.js?169",
						t.onload=function(){
							VK.Retargeting.Init("VK-RTRG-1024683-hQphO"),
							VK.Retargeting.Hit()
							},
						document.head.appendChild(t)
					}();`}
					</script>
					<noscript>
						{`<img
							src="https://vk.com/rtrg?p=VK-RTRG-1024683-hQphO"
							style="position:fixed; left:-999px;"
							alt=""
						/>`}
					</noscript>
				</Helmet>
			)}
		</div>
	)
}

export default App
