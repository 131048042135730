import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './TransformCard.scss'

import work from '../../../../../../../../assets/media/landing/x-work.png'
import campus from '../../../../../../../../assets/media/landing/x-campus.png'

const TransformCard = React.memo(({ type, children }) => {
	const [isFlipped, setIsFlipped] = useState(false)

	return (
		<div className={cn('transform-card__wrapper', { flipped: isFlipped })}>
			<div className="transform-card-shadow" />
			<div
				className="transform-card"
				onClick={() => {
					setIsFlipped(!isFlipped)
				}}
				onKeyPress={() => {
					setIsFlipped(!isFlipped)
				}}
				role="button"
				tabIndex={0}
			>
				<div className="transform-card__face transform-card__face--back">
					<div className="transform-card__face-img-wrapper">
						<img
							className="transform-card__face-img"
							src={type === 'work' ? work : campus}
							alt={type === 'work' ? 'x-work logo' : 'x-campus logo'}
						/>
					</div>
					<div className="transform-card__face-text-wrapper">{children}</div>
				</div>
				<div className="transform-card__face transform-card__face--front">
					<img
						className="transform-card__face-img"
						src={type === 'work' ? work : campus}
						alt={type === 'work' ? 'x-work logo' : 'x-campus logo'}
					/>
				</div>
			</div>
		</div>
	)
})

const { string, node } = PropTypes

TransformCard.propTypes = {
	type: string.isRequired,
	children: node.isRequired,
}

export default TransformCard
