import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'

import Tooltip from '../../Tooltip/Tooltip'

import { getStatusByNumber } from '../../../functions/common'

import statusTooltipText from '../constants'

import './Status.scss'

const Status = memo(({ status }) => {
	const [tooltip, setTooltip] = useState(false)

	const handleTooltipHover = (value) => () => {
		setTooltip(value)
	}

	return (
		<div className="status">
			<span>{getStatusByNumber(status)}</span>
			<i onMouseEnter={handleTooltipHover(true)} onMouseLeave={handleTooltipHover(false)}>
				<Tooltip show={tooltip}>{statusTooltipText(status)}</Tooltip>
			</i>
		</div>
	)
})

const { number } = PropTypes

Status.propTypes = {
	status: number.isRequired,
}

export default Status
