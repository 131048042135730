import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import NavItem from './NavItem/NavItem'

import {
	getIsApproved,
	getIsEmpty,
} from '../../../../store/reducers/authorization/profile/profile-selectors'

import { addNot } from '../../../../store/reducers/common/global-nots/global-nots'

import './Nav.scss'

const Nav = memo(
	({
		items,
		additionalClass,
		withoutExact,
		isActiveResponds,
		isActiveOffers,
		isActiveRecomends,
	}) => {
		const dispatch = useDispatch()

		const isEmpty = useSelector(getIsEmpty)
		const isApproved = useSelector(getIsApproved)

		const handleClick = () => {
			if (isEmpty || !isApproved) {
				dispatch(
					addNot({
						not: 'Данная страница станет доступна после одобрения анкеты',
						type: 'info',
					})
				)
			}
		}

		return (
			/* eslint-disable-next-line */
			<nav className="nav" onClick={handleClick}>
				{items.map(({ icon, text, link }, i) => (
					<NavItem
						key={`${text}-${link}`}
						additionalClass={additionalClass}
						exact={!withoutExact && !i}
						index={i + 1}
						icon={icon}
						text={text}
						link={link}
						isActiveResponds={isActiveResponds}
						isActiveOffers={isActiveOffers}
						isActiveRecomends={isActiveRecomends}
					/>
				))}
			</nav>
		)
	}
)

Nav.defaultProps = {
	additionalClass: '',
	withoutExact: false,
	isActiveResponds: false,
	isActiveOffers: false,
	isActiveRecomends: false,
}

const { string, bool, arrayOf, objectOf } = PropTypes

Nav.propTypes = {
	items: arrayOf(objectOf(string)).isRequired,
	additionalClass: string,
	withoutExact: bool,
	isActiveResponds: bool,
	isActiveOffers: bool,
	isActiveRecomends: bool,
}

export default Nav
