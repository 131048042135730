import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { Link } from 'react-router-dom'

import Avatar from '../Avatar/Avatar'
import Edit from '../QuestionnaireContent/EditQuestionnaireLink/EditQuestionnaireLink'
import PointsCounter from '../PointsCounter/PointsCounter'
import Status from './Status/Status'

import { getIsMobile } from '../../store/reducers/common/app/app-selectors'

import {
	getName,
	getStatus,
	getSurname,
	getPhoto,
	getIsEmpty,
	getMoney,
} from '../../store/reducers/authorization/profile/profile-selectors'

import './ProfileBlock.scss'

const ProfileBlock = memo(({ main }) => {
	const isEmpty = useSelector(getIsEmpty)

	const name = useSelector(getName)
	const surname = useSelector(getSurname)
	const status = useSelector(getStatus)
	const photo = useSelector(getPhoto)
	const money = useSelector(getMoney)

	const isMobile = useSelector(getIsMobile)

	return (
		<div className={cn('profile-block', { main })}>
			{isMobile && (
				<div className="edit__profile">
					<Edit />
				</div>
			)}
			<div className="profile-block__profile">
				{main ? (
					<Avatar large={main} image={photo} />
				) : (
					<Link to="/profile">
						<Avatar image={photo} />
					</Link>
				)}
				<div className="profile-block__info">
					<div className="name">
						{(name || surname) && !isEmpty ? `${name} ${surname}` : 'Гость'}
					</div>
					{main ? <Status status={status} /> : <PointsCounter withIcon count={money} />}
				</div>
			</div>
		</div>
	)
})

ProfileBlock.defaultProps = {
	main: false,
}

const { bool } = PropTypes

ProfileBlock.propTypes = {
	main: bool,
}

export default ProfileBlock
