const statusTooltipText = (status) => {
	switch (status) {
		case 1:
			return 'Ваша анкета будет просмотрена администратором в течении трех дней'
		case 2:
			return 'Ваша анкета активна. Вы являетесь участником сообщества X-Cluster'
		case 3:
			return 'Анкета отклонена'
		case 4:
			return 'Заполните анкету для дальнейшего участия в жизни сообщества'
		default:
			return 'Анкета неактивна'
	}
}

export default statusTooltipText
