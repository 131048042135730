import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'

import DisintegrationModalContent from './IntegrationsContent/ModalsContent/DisintegrationModalContent/DisintegrationModalContent'
import IntegrationsContent from './IntegrationsContent/IntegrationsContent'
import IntegrationModalContent from './IntegrationsContent/ModalsContent/IntegrationModalContent/IntegrationModalContent'
import ModalContainer from '../../../../components/ModalContainer/ModalContainer'

import ProfileContext from './ProfileContext'

import {
	requestBotActiveKey,
	setBotKey,
} from '../../../../store/reducers/authorization/profile/profile'

import {
	getBotByType,
	getBotKey,
} from '../../../../store/reducers/authorization/profile/profile-selectors'

import './BotsIntegrations.scss'

const BotsIntegrations = memo(({ bots, showModal, isModal }) => {
	const dispatch = useDispatch()

	const [integrationModal, setIntegrationModal] = useState(false)
	const [disintegrationModal, setDisintegrationModal] = useState(false)
	const [botType, setBotType] = useState(null)

	const botInfo = useSelector(getBotByType(botType))
	const botKey = useSelector(getBotKey)

	const handleModalToggle =
		(callback, modalState) =>
		(type = null) =>
		() => {
			if (modalState) {
				setBotType(type)
			} else {
				setTimeout(() => {
					setBotType(null)
					if (botKey) {
						dispatch(setBotKey({ key: null }))
					}
				}, 300)
			}
			callback(modalState)
		}

	useEffect(() => {
		if (botType && integrationModal) {
			dispatch(requestBotActiveKey(botType))
		}
	}, [dispatch, botType, integrationModal])

	return (
		<div className={cn('bot-integrations', { modal: isModal })}>
			{isModal ? (
				<ModalContainer className="123" show={showModal} onClose={() => {}}>
					<ProfileContext.Provider
						value={{
							openIntegrationModal: handleModalToggle(setIntegrationModal, true),
							openDisintegrationModal: handleModalToggle(setDisintegrationModal, true),
						}}
					>
						<IntegrationsContent bots={bots} isModal />
					</ProfileContext.Provider>
				</ModalContainer>
			) : (
				<ProfileContext.Provider
					value={{
						openIntegrationModal: handleModalToggle(setIntegrationModal, true),
						openDisintegrationModal: handleModalToggle(setDisintegrationModal, true),
					}}
				>
					<IntegrationsContent bots={bots} />
				</ProfileContext.Provider>
			)}
			<ModalContainer
				show={integrationModal}
				onClose={handleModalToggle(setIntegrationModal, false)()}
				style={{ minHeight: '365px' }}
			>
				<IntegrationModalContent
					botKey={botKey}
					info={botInfo}
					onClose={handleModalToggle(setIntegrationModal, false)()}
				/>
			</ModalContainer>
			<ModalContainer
				show={disintegrationModal}
				onClose={handleModalToggle(setDisintegrationModal, false)()}
			>
				<DisintegrationModalContent
					info={botInfo}
					onClose={handleModalToggle(setDisintegrationModal, false)()}
				/>
			</ModalContainer>
		</div>
	)
})

BotsIntegrations.defaultProps = {
	showModal: false,
	isModal: false,
}

const { string, number, bool, objectOf, arrayOf, oneOfType } = PropTypes

BotsIntegrations.propTypes = {
	bots: arrayOf(objectOf(oneOfType([string, number, bool]))).isRequired,
	showModal: bool,
	isModal: bool,
}

export default BotsIntegrations
