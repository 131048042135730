/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

import commonApi from '../../../../api/common-api'
import responsesApi from '../../../../apps/XWork/api/responses-api'
import vacanciesApi from '../../../../apps/XWork/api/vacancies-api'

import { addNot } from '../../common/global-nots/global-nots'
import suggestionsApi from '../../../../apps/XWork/api/suggestions-api'

const initialState = {
	vacancies: [],
	count: 0,
	vacancyInfo: {},
	filters: {
		limit: 10,
		offset: 0,
		work_schedule: [],
		is_suitable: false,
		min_payment: 0,
		max_payment: 0,
		currency_key: 'rub',
		skills: [],
		specs: [],
	},
	minPossiblePayment: 0,
	maxPossiblePayment: 0,
	isLoading: false,
	isLoaded: false,
	failed: false,
}

const vacancies = createSlice({
	name: 'vacancies',
	initialState,
	reducers: {
		setVacancies: (state, action) => {
			state.vacancies = action.payload.vacancies
		},

		setCount: (state, action) => {
			state.count = action.payload.count
		},

		setVacancyInfo: (state, action) => {
			state.vacancyInfo = action.payload.info
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setMinPossiblePayment: (state, action) => {
			state.minPossiblePayment = action.payload.minPossiblePayment
		},

		setMaxPossiblePayment: (state, action) => {
			state.maxPossiblePayment = action.payload.maxPossiblePayment
		},

		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},

		setIsLoaded: (state, action) => {
			state.isLoaded = action.payload.isLoaded
		},

		setFailed: (state, action) => {
			state.failed = action.payload.failed
		},
	},
})

const { actions, reducer } = vacancies

export const {
	setVacancies,
	setCount,
	setVacancyInfo,
	setFilters,
	setMinPossiblePayment,
	setMaxPossiblePayment,
	setIsLoading,
	setIsLoaded,
	setFailed,
} = actions

// Получение списка вакансий
export const requestVacancies =
	(clean = false) =>
	async (dispatch, getState) => {
		const {
			vacancies: { vacancies, filters, isLoaded },
		} = getState()

		dispatch(setIsLoading({ isLoading: true }))

		const res = await vacanciesApi.requestVacancies(filters)

		if (res.success) {
			if (clean) dispatch(setVacancies({ vacancies: res.result.vacancy }))
			else dispatch(setVacancies({ vacancies: [...vacancies, ...res.result.vacancy] }))

			dispatch(setCount({ count: res.result.count }))

			if (!isLoaded) {
				dispatch(setIsLoaded({ isLoaded: true }))
			}
		} else {
			dispatch(addNot({ not: res.result.msg, type: 'error' }))
		}

		dispatch(setIsLoading({ isLoading: false }))
	}

// Получение информации о вакансии по id
export const requestVacancyById = (id) => async (dispatch) => {
	const res = await vacanciesApi.requestVacancyById(id)

	if (res.success) {
		dispatch(setVacancyInfo({ info: res.result }))
	} else {
		dispatch(setFailed({ failed: true }))
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Получение минимальной и максимальной зарплаты для фильтра
export const requestMinMax = (type) => async (dispatch) => {
	const res = await commonApi.requestMinMax(11, type)

	if (res.success) {
		dispatch(setMinPossiblePayment({ minPossiblePayment: res.result.min }))
		dispatch(setMaxPossiblePayment({ maxPossiblePayment: res.result.max }))
		dispatch(setFilters({ min_payment: res.result.min, max_payment: res.result.max }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Откликнуться на вакансию
export const createResponse = (id) => async (dispatch) => {
	const res = await responsesApi.createResponse(id, 11)

	if (res.success) {
		dispatch(setVacancies({ vacancies: [] }))
		dispatch(requestVacancies())
		dispatch(requestVacancyById(id))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Взять вакансию в работу рекрутером
export const takeToWork = (id) => async (dispatch) => {
	const res = await suggestionsApi.takeToWork(id, 11)

	if (res.success) {
		dispatch(setVacancies({ vacancies: [] }))
		dispatch(requestVacancies())
		dispatch(requestVacancyById(id))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Отменить отклик
export const cancelResponse = (vacancyId, responseId) => async (dispatch) => {
	const res = await responsesApi.cancelResponse(responseId)

	if (res.success) {
		dispatch(setVacancies({ vacancies: [] }))
		dispatch(requestVacancies())
		dispatch(requestVacancyById(vacancyId))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Отменить взятие в работу рекрутером
export const cancelRecruterWork = (vacancyId, type) => async (dispatch) => {
	const res = await suggestionsApi.cancelRecruterWork(vacancyId, type)

	if (res.success) {
		dispatch(setVacancies({ vacancies: [] }))
		dispatch(requestVacancies())
		dispatch(requestVacancyById(vacancyId))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

// Отправить тестовое задание
export const sendTestTask = (id, data) => async (dispatch) => {
	const res = await responsesApi.sendTestTask(data)

	if (res.success) {
		dispatch(setVacancies({ vacancies: [] }))
		dispatch(requestVacancies())
		dispatch(requestVacancyById(id))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

export default reducer
