/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

import commonApi from '../../../../api/common-api'

import { addNot } from '../../common/global-nots/global-nots'

const initialState = {
	faqArr: [],
}

const aboutClub = createSlice({
	name: 'aboutClub',
	initialState,
	reducers: {
		setFAQ: (state, action) => {
			state.faqArr = action.payload.faqArr
		},
	},
})

const { reducer, actions } = aboutClub

export const { setFAQ } = actions

export const requestFAQ = () => async (dispatch) => {
	const res = await commonApi.getLocal(1)

	if (res.success) {
		dispatch(setFAQ({ faqArr: res.result }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

export default reducer
