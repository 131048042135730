import React from 'react'

import ContactsFooter from './ContactsFooter/ContactsFooter'

import SocialIcons from '../Main/JoinUs/SocialIcons/SocialIcons'

import './Contacts.scss'

import map from '../../../../../../assets/media/landing/map.png'

import marker from '../../../../../../assets/media/landing/icons/map-marker.svg'
import tel from '../../../../../../assets/media/landing/icons/phone.svg'
import mail from '../../../../../../assets/media/landing/icons/mail.svg'

const Contacts = React.memo(() => (
	<div className="contacts-section">
		<div className="contacts-section__info-wrapper">
			<div className="contacts-section__info-block">
				<div className="contacts-section__info-title title">наши контакты</div>
				<div className="contacts-section__info-contacts">
					<div className="contacts-section__info-address">
						<i style={{ backgroundImage: `url(${marker})` }} />
						<span>Адрес: 236022, Россия, г. Калининград, ул. Генерал-лейтенанта Озерова, 17б</span>
					</div>
					<div className="contacts-section__info-tel">
						<i style={{ backgroundImage: `url(${tel})` }} />
						<span>+7 (911) 452-92-03</span>
					</div>
					<div className="contacts-section__info-mail">
						<i style={{ backgroundImage: `url(${mail})` }} />
						<span>office@x-cluster.com</span>
					</div>
					<SocialIcons />
				</div>
			</div>
			<div className="contacts-section__map-block">
				<img src={map} alt="map" />
			</div>
		</div>
		<ContactsFooter />
	</div>
))

export default Contacts
