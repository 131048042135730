export const getName = ({
	profile: {
		user: { name },
	},
}) => name

export const getSurname = ({
	profile: {
		user: { surname },
	},
}) => surname

export const getEmail = ({
	profile: {
		user: { email },
	},
}) => email

export const getId = ({
	profile: {
		user: { id },
	},
}) => id

export const getStatus = ({
	profile: {
		user: { status },
	},
}) => status

export const getStatusMsg = ({
	profile: {
		user: { status_msg: statusMsg },
	},
}) => statusMsg

export const getPhoto = ({
	profile: {
		user: { image },
	},
}) => image

export const getPartnerAccess = ({
	profile: {
		user: { partner_access: partnerAccess },
	},
}) => partnerAccess

export const getRole = ({
	profile: {
		user: { role },
	},
}) => role

export const getMoney = ({
	profile: {
		user: { money },
	},
}) => money

export const getIsModerating = ({
	profile: {
		user: { status },
	},
}) => status === 1

export const getIsApproved = ({
	profile: {
		user: { status },
	},
}) => status === 2

export const getIsRejected = ({
	profile: {
		user: { status },
	},
}) => status === 3

export const getIsEmpty = ({
	profile: {
		user: { status },
	},
}) => status === 4

export const getGroups = ({ profile: { groups } }) => groups

export const getHistory = ({ profile: { history } }) => history

export const getHistLoaded = ({ profile: { histLoaded } }) => histLoaded

export const getHistOffset = ({ profile: { histOffset } }) => histOffset

export const getHistCount = ({ profile: { histCount } }) => histCount

export const getBots = ({ profile: { bots } }) => bots

export const getBotByType =
	(type) =>
	({ profile: { bots } }) =>
		bots.find((bot) => bot.type === type)

export const getBotKey = ({ profile: { botKey } }) => botKey

export const getIsBotsLoading = ({ profile: { isBotsLoading } }) => isBotsLoading

export const getIsUserHaveBot = ({ profile: { bots } }) => bots.find((item) => item.is_use)
