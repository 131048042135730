import { configureStore } from '@reduxjs/toolkit'

import app from './reducers/common/app/app'
import globalNots from './reducers/common/global-nots/global-nots'
import local from './reducers/common/local/local'
import notifications from './reducers/common/notifications/notifications'

import authorization from './reducers/authorization/reducers'
import xCluster from './reducers/x-cluster/reducers'

import xWork from './reducers/x-work/reducers'

const reducers = {
	app,
	local,
	globalNots,
	notifications,
	...authorization,
	...xCluster,
	...xWork,
}

const middleware = (getDefaultMiddleware) =>
	getDefaultMiddleware({
		immutableCheck: false,
		serializableCheck: false,
		thunk: true,
	})

const store = configureStore({ reducer: reducers, middleware })

window.store = store

export default store
