import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import IconItem from './IconItem/IconItem'

const Icons = React.memo(({ adaptive }) => (
	<div className={cn('icons-wrapper', { adaptive })}>
		<IconItem label="Форумы" classname="forums" position="left" />
		<IconItem label="Конференции" classname="conferences" position="left" />
		<IconItem label="Фестивали" classname="festival" position="left" />
		<IconItem label="Митапы" classname="meetup" position="rigth" />
		<IconItem label="Воркшопы" classname="workshops" position="rigth" />
		<IconItem label="Хакатоны" classname="hackaton" position="rigth" />
	</div>
))

const { bool } = PropTypes

Icons.defaultProps = {
	adaptive: false,
}

Icons.propTypes = {
	adaptive: bool,
}

export default Icons
