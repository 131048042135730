import React, { forwardRef, memo, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { generateID } from '../../../functions/common'

import './Input.scss'

const Input = memo(
	forwardRef(
		(
			{
				type = 'text',
				name,
				className,
				label,
				placeholder,
				value,
				onChange = () => {},
				// onBlur = () => {},
				onFocus = () => {},
				onKeyUp = () => {},
				onClick = () => {},
				disabled,
				error,
				autofocus = false,
				offAutoComplete = false,
			},
			ref
		) => {
			const [isValidate, setIsValidate] = useState(false)

			const handleOnBlur = () => {
				// onBlur()
				if (error) {
					setIsValidate(true)
				} else {
					setIsValidate(false)
				}
			}

			const id = generateID('in-')

			const inputRef = useRef()

			useEffect(() => {
				if (autofocus) {
					inputRef.current.focus()
				}
			})

			return (
				<div ref={ref} className={cn('input-main-container', className, { error: isValidate })}>
					<input
						ref={inputRef}
						name={name}
						id={id}
						type={type}
						placeholder={placeholder}
						value={value}
						onChange={onChange}
						onBlur={handleOnBlur}
						onFocus={onFocus}
						onKeyUp={onKeyUp}
						onClick={onClick}
						disabled={disabled}
						autoComplete={offAutoComplete ? 'off' : 'on'}
					/>
					{label && <label htmlFor={id}>{label}</label>}
				</div>
			)
		}
	)
)

Input.defaultProps = {
	name: '',
	type: 'text',
	className: '',
	placeholder: '',
	value: '',
	error: false,
	onChange: () => {},
	// onBlur: () => {},
	onFocus: () => {},
	onKeyUp: () => {},
	onClick: () => {},
	disabled: false,
	autofocus: false,
	offAutoComplete: false,
}

const { string, bool, func } = PropTypes

Input.propTypes = {
	type: string,
	name: string,
	className: string,
	label: string.isRequired,
	placeholder: string,
	value: string,
	onChange: func,
	// onBlur: func,
	onFocus: func,
	onKeyUp: func,
	onClick: func,
	disabled: bool,
	autofocus: bool,
	offAutoComplete: bool,
	error: bool,
}

export default Input
