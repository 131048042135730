/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

import {
	removeToken,
	removeInfo,
	setToken,
	getRefFlag,
	getFromFlag,
} from '../../../../functions/localStorage'

import { addNot } from '../../common/global-nots/global-nots'
import { setUser } from '../profile/profile'
import { setUpdating, setWs } from '../../common/app/app'

import authApi from '../../../../apps/Authorization/api/auth-api'

const initialState = {
	isAuth: false,
	nextStep: false,
	phone: '',
	type: null,
	presence: false,
}

const auth = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setIsAuth: (state, action) => {
			state.isAuth = action.payload.isAuth
		},

		setNextStep: (state, action) => {
			state.nextStep = action.payload.nextStep
		},

		setPhone: (state, action) => {
			state.phone = action.payload.phone
		},

		setType: (state, action) => {
			state.type = action.payload.type
		},

		setPresence: (state, action) => {
			state.presence = action.payload.presence
		},
	},
})

const { reducer, actions } = auth

export const { setIsAuth, setNextStep, setPhone, setType, setPresence } = actions

/*
	Первое окно входа
	Регистрация
*/
export const regAuth =
	(params, reg = false) =>
	async (dispatch) => {
		const { phone } = params

		const res = reg ? await authApi.reg(params) : await authApi.auth(params)

		if (res.success) {
			if (phone) dispatch(setPhone({ phone }))
			dispatch(setType({ type: res.result.trigger }))
			dispatch(setPresence({ presence: res.result.presence }))
		} else {
			dispatch(addNot({ not: res.result.msg, type: 'error' }))
		}

		return res.success
	}

/*
	Второе окно входа
*/
export const verif = (pin) => async (dispatch, getState) => {
	const {
		auth: { phone },
	} = getState()

	const ref = getRefFlag()
	const from = getFromFlag()

	const data = new FormData()
	data.append('ref', ref)
	data.append('from', from)

	const res = await authApi.verif({ phone, pin, data })

	if (res.success) {
		const { jwt_user: jwtUser, user } = res.result

		dispatch(setUser({ user }))
		setToken(jwtUser)
		dispatch(setIsAuth({ isAuth: true }))

		dispatch(setPhone({ phone: '' }))
		dispatch(setNextStep({ nextStep: false }))
	} else {
		if (res.result.code === 13) {
			dispatch(setNextStep({ nextStep: false }))
			dispatch(
				addNot({
					not: 'Вы трижды ввели неверный код. Попробуйте ввести номер телефона заново',
					type: 'info',
				})
			)
		} else {
			dispatch(addNot({ not: res.result.msg, type: 'error' }))
		}
	}
}

export const refreshPin = (type) => async (dispatch, getState) => {
	const {
		auth: { phone },
	} = getState()

	const res = await authApi.refreshPin(phone, type)

	if (res.success) {
		dispatch(setType({ type }))
	} else {
		dispatch(addNot({ not: res.result.msg, type: 'error' }))
	}
}

/*
	Выход из приложения
*/
export const logout = () => async (dispatch, getState) => {
	const {
		app: { ws },
	} = getState()

	dispatch(setIsAuth({ isAuth: false }))
	dispatch(setUser({ user: {} }))

	removeToken()
	removeInfo()

	dispatch(setUpdating({ updating: false }))
	ws.close()
	dispatch(setWs({ ws: null }))
}

export default reducer
