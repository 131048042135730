import React, { forwardRef, memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './Menu.scss'

const Menu = memo(
	forwardRef(({ children, mobileMenu, right }, ref) => (
		<aside className={cn('app-side-menu', { mobileMenu, right })} ref={ref}>
			{children}
		</aside>
	))
)

Menu.defaultProps = {
	mobileMenu: false,
	right: false,
}

const { bool, node } = PropTypes

Menu.propTypes = {
	children: node.isRequired,
	mobileMenu: bool,
	right: bool,
}

export default Menu
