import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Icon from '../../Icon/Icon'

import './IconButton.scss'

const IconButton = memo(({ className, icon, size, iconSize, onClick }) => (
	<button
		type="button"
		className={cn('button-icon-main', className)}
		style={{ height: size, width: size }}
		onClick={onClick}
	>
		<Icon icon={icon} size={iconSize} />
	</button>
))

IconButton.defaultProps = {
	className: '',
	size: 24,
	iconSize: 24,
	onClick: () => {},
}

const { string, number, func } = PropTypes

IconButton.propTypes = {
	className: string,
	icon: string.isRequired,
	size: number,
	iconSize: number,
	onClick: func,
}

export default IconButton
