import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './Preloader.scss'

const Preloader = memo(({ full }) => (
	<div className={cn('preloader-component', { full })}>
		<div className="preloader" />
	</div>
))

Preloader.defaultProps = {
	full: false,
}

const { bool } = PropTypes

Preloader.propTypes = {
	full: bool,
}

export default Preloader
