/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

import commentsApi from '../../../../apps/XCluster/api/comments-api'

const initialState = {
	isLoading: false,
	comments: [],
	filters: {
		limit: 5,
		offset: 0,
	},
	type: 0,
	count: 0,
}

const comments = createSlice({
	name: 'comments',
	initialState,
	reducers: {
		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},

		setComments: (state, action) => {
			state.comments = action.payload.comments
		},

		setCount: (state, action) => {
			state.count = action.payload.count
		},

		setType: (state, action) => {
			state.type = action.payload.type
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},
	},
})

const { reducer, actions } = comments

export const { setIsLoading, setComments, setFilters, setCount, setType } = actions

export const requestComments = () => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const {
		comments: { filters },
		events: {
			eventInfo: { id },
		},
	} = getState()

	const res = await commentsApi.requestComments(filters, id)

	if (res.success) {
		dispatch(setComments({ comments: res.result.comments }))
		dispatch(setCount({ count: res.result.count }))
	}

	dispatch(setIsLoading({ isLoading: false }))
}

export const addComment = (msg) => async (dispatch, getState) => {
	const {
		comments: { type },
		events: {
			eventInfo: { id },
		},
	} = getState()

	const res = await commentsApi.addComment(msg, id, type)

	if (res.success) {
		dispatch(requestComments())
	}
}

export default reducer
