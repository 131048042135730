import React, { memo, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import Buttons from '../IntegrationsModalButtons/IntegrationsModalButtons'
import Icon from '../../../../../../../components/Icon/Icon'
import Preloader from '../../../../../../../components/Preloader/Preloader'
import Subtitle from '../IntegrationsModalSubtitle/IntegrationsModalSubtitle'
import Title from '../IntegrationsModalTitle/IntegrationsModalTitle'

import { addNot } from '../../../../../../../store/reducers/common/global-nots/global-nots'

import { onPressEnter } from '../../../../../../../functions/common'

import copySvg from '../../../../../../../assets/media/svg/copy.svg'

import './IntegrationModalContent.scss'

const IntegrationModalContent = memo(({ botKey, info, onClose }) => {
	if (!info) return null
	if (!botKey) return <Preloader />

	const dispatch = useDispatch()

	const code = useRef()

	const { title, icon, link, is_use: isUse } = info

	const copyText = () => {
		const text = code.current.textContent
		navigator.clipboard.writeText(text)
		dispatch(addNot({ not: 'Код скопирован в буфер обмена', type: 'success' }))
	}

	return (
		<div className="integration-modal-content">
			<Title label={`Интеграция c ${title}`} icon={icon} />
			<Subtitle text={`Используйте код для интеграции с ${title}`} />
			<div ref={code} className="integration-modal-content__code">
				{botKey}
			</div>
			<div
				onClick={copyText}
				onKeyPress={onPressEnter(copyText)}
				className="integration-modal-content__copy-code"
				role="button"
				tabIndex={0}
			>
				<div className="copy-icon">
					<Icon icon={copySvg} size={16} />
				</div>
				Скопировать код
			</div>

			{isUse && (
				<div className="integration-modal-content__success">Поздравляем, бот проинтегрирован</div>
			)}

			<Buttons submitText="Интегрировать" onCancelClick={onClose} link={link} success={isUse} />
		</div>
	)
})

IntegrationModalContent.defaultProps = {
	botKey: '',
	info: {},
}

const { string, bool, func, objectOf, oneOfType } = PropTypes

IntegrationModalContent.propTypes = {
	botKey: string,
	info: objectOf(oneOfType([string, bool])),
	onClose: func.isRequired,
}

export default IntegrationModalContent
