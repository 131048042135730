import React from 'react'
import cn from 'classnames'

import './SectionContainer.scss'

const SectionContainer = React.memo(
	React.forwardRef(({ children, classname = '', id, multiple }, ref) => (
		<section ref={ref} className={cn('section', classname, { multiple })} id={id}>
			{children}
		</section>
	))
)

export default SectionContainer
