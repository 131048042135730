import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './Button.scss'

const Button = memo(
	({
		className,
		label,
		type,
		white,
		red,
		green,
		purple,
		arrow,
		disabled,
		uppercase,
		submit,
		onClick,
	}) => (
		<button
			type={submit ? 'submit' : 'button'}
			className={cn('button-main', type, className, { white, red, green, purple, uppercase })}
			onClick={onClick}
			disabled={disabled}
		>
			<span className="bg" />
			<span className="label">{label}</span>
			{arrow && <span className="arrow" />}
		</button>
	)
)

Button.defaultProps = {
	className: '',
	type: 'lg',
	white: false,
	red: false,
	green: false,
	purple: false,
	arrow: false,
	disabled: false,
	uppercase: false,
	submit: false,
	onClick: () => {},
}

const { string, bool, func } = PropTypes

Button.propTypes = {
	className: string,
	label: string.isRequired,
	type: string,
	white: bool,
	red: bool,
	green: bool,
	purple: bool,
	arrow: bool,
	disabled: bool,
	uppercase: bool,
	submit: bool,
	onClick: func,
}

export default Button
